// ----------------------------------------------------------------------

export const _pricingHome = [
    {
        license: 'Standard',
        price: '59',
        icons: ['/assets/icons/platforms/ic_js.svg'],
        commons: ['One end products', '12 months updates', '6 months of support'],
        options: [
            { title: 'JavaScript version', disabled: false },
            { title: 'TypeScript version', disabled: true },
            { title: 'Design resources', disabled: true },
            { title: 'Commercial applications', disabled: true },
        ],
    },
    {
        license: 'Plus',
        price: '99',
        icons: [
            '/assets/icons/platforms/ic_js.svg',
            '/assets/icons/platforms/ic_ts.svg',
            '/assets/icons/platforms/ic_figma.svg',
        ],
        commons: ['One end products', '12 months updates', '6 months of support'],
        options: [
            { title: 'JavaScript version', disabled: false },
            { title: 'TypeScript version', disabled: false },
            { title: 'Design resources', disabled: false },
            { title: 'Commercial applications', disabled: true },
        ],
    },
    {
        license: 'Extended',
        price: '249',
        icons: [
            '/assets/icons/platforms/ic_js.svg',
            '/assets/icons/platforms/ic_ts.svg',
            '/assets/icons/platforms/ic_figma.svg',
        ],
        commons: ['One end products', '12 months updates', '6 months of support'],
        options: [
            { title: 'JavaScript version', disabled: false },
            { title: 'TypeScript version', disabled: false },
            { title: 'Design resources', disabled: false },
            { title: 'Commercial applications', disabled: false },
        ],
    },
];

export const _pricingMarketing = [
    {
        license: 'Basic',
        price: '29',
        icon: '/assets/icons/pricing/ic_plan_basic03.svg',
        caption: 'Proin viverra, ligula sit amet ultrices semper, ligula arcu tristique sapien',
        options: ['Auto update mode', 'Online operator 24/7', 'International posting'],
    },
    {
        license: 'Starter',
        price: '59',
        icon: '/assets/icons/pricing/ic_plan_starter03.svg',
        caption: 'Sed lectus. Sed consequat, leo eget bibendum sodales',
        options: ['Auto update mode', 'Online operator 24/7', 'Unique newsletters', 'International posting'],
    },
    {
        license: 'Premium',
        price: '99',
        icon: '/assets/icons/pricing/ic_plan_premium03.svg',
        caption: 'Maecenas nec odio et ante tincidunt tempus.',
        options: [
            'Auto update mode',
            'Online operator 24/7',
            'Unique newsletters',
            'International posting',
            '20 Design templates',
        ],
    },
];

export const _pricing01 = [
    {
        license: 'Basic',
        price: 'Free',
        icon: '/assets/icons/pricing/ic_plan_basic01.svg',
        options: [
            { title: '3 prototypes', disabled: false },
            { title: '3 boards', disabled: false },
            { title: 'Up to 5 team members', disabled: true },
            { title: 'Advanced security', disabled: true },
            { title: 'Permissions & workflows', disabled: true },
        ],
    },
    {
        license: 'Starter',
        price: '4.99',
        icon: '/assets/icons/pricing/ic_plan_starter01.svg',
        options: [
            { title: '3 prototypes', disabled: false },
            { title: '3 boards', disabled: false },
            { title: 'Up to 5 team members', disabled: false },
            { title: 'Advanced security', disabled: true },
            { title: 'Permissions & workflows', disabled: true },
        ],
    },
    {
        license: 'Premium',
        price: '9.99',
        icon: '/assets/icons/pricing/ic_plan_premium01.svg',
        options: [
            { title: '3 prototypes', disabled: false },
            { title: '3 boards', disabled: false },
            { title: 'Up to 5 team members', disabled: false },
            { title: 'Advanced security', disabled: false },
            { title: 'Permissions & workflows', disabled: false },
        ],
    },
];

export const _pricing02 = [
    {
        license: 'Start',
        caption: 'Next 3 months',
        price: 'Free',
        icon: '/assets/icons/pricing/ic_plan_basic02.svg',
        options: [
            { title: '3 prototypes', tootip: '3 prototypes', disabled: false },
            { title: '3 boards', tootip: '3 boards', disabled: false },
            {
                title: 'Up to 5 team members',
                tootip: 'Up to 5 team members',
                disabled: true,
            },
            {
                title: 'Advanced security',
                tootip: 'Advanced security',
                disabled: true,
            },
            {
                title: 'Permissions & workflows',
                tootip: 'Permissions & workflows',
                disabled: true,
            },
            { title: 'Long feature one', tootip: 'Long feature one', disabled: true },
        ],
    },
    {
        license: 'Pro',
        caption: 'Charging $456 per/y',
        price: '4.99',
        icon: '/assets/icons/pricing/ic_plan_starter02.svg',
        options: [
            { title: '3 prototypes', tootip: '3 prototypes', disabled: false },
            { title: '3 boards', tootip: '3 boards', disabled: false },
            {
                title: 'Up to 5 team members',
                tootip: 'Up to 5 team members',
                disabled: false,
            },
            {
                title: 'Advanced security',
                tootip: 'Advanced security',
                disabled: true,
            },
            {
                title: 'Permissions & workflows',
                tootip: 'Permissions & workflows',
                disabled: true,
            },
            { title: 'Long feature one', tootip: 'Long feature one', disabled: true },
        ],
    },
    {
        license: 'Business',
        caption: 'Charging $696 per/y',
        price: '9.99',
        icon: '/assets/icons/pricing/ic_plan_premium02.svg',
        options: [
            { title: '3 prototypes', tootip: '3 prototypes', disabled: false },
            { title: '3 boards', tootip: '3 boards', disabled: false },
            {
                title: 'Up to 5 team members',
                tootip: 'Up to 5 team members',
                disabled: false,
            },
            {
                title: 'Advanced security',
                tootip: 'Advanced security',
                disabled: false,
            },
            {
                title: 'Permissions & workflows',
                tootip: 'Permissions & workflows',
                disabled: false,
            },
            {
                title: 'Long feature one',
                tootip: 'Long feature one',
                disabled: false,
            },
        ],
    },
];

export const _plans = [
    {
        _id: {
            $oid: '',
        },
        // license: 'Lite',
        license: 'Free',
        // Angel, 아이디어 검증을 위한
        label: 'ohmyappHomepage.subscribe.lite.label',
        // 연간 결제 시, ₩ 500,000
        caption: 'ohmyappHomepage.plans.test.caption',
        // ₩ 50,000
        price: 'ohmyappHomepage.plans.free.price',
        amount: 0,
        icon: '/assets/images/plans/plans_ohmy_coin.gif',
        button: 'ohmyappHomepage.plans.free.start_button',
        explanation: 'ohmyappHomepage.plans.free.explanation',
        options: [
            {
                disabled: true,
                // 운영 서버
                title: 'ohmyappHomepage.plans.options.manage_server_title',
                // 운영 서버의 갯수
                tootip: 'ohmyappHomepage.plans.options.manage_server_help_msg',
                // 1 개
                contents: 'ohmyappHomepage.plans.test.manage_server',
            },
            {
                // 개발 서버
                title: 'ohmyappHomepage.plans.options.devolop_server_title',
                // 개발 서버의 갯수
                tootip: 'ohmyappHomepage.plans.options.develop_server_help_msg',
                // 1 개
                contents: 'ohmyappHomepage.plans.test.develop_server',
            },
            {
                // 출시 가능한 앱
                title: 'ohmyappHomepage.plans.options.app_option_title',
                // 출시 가능한 앱의 갯수
                tootip: 'ohmyappHomepage.plans.options.app_option_help_msg',
                // 1 개
                contents: 'ohmyappHomepage.plans.test.app_option',
            },
            {
                // 저장 공간
                title: 'ohmyappHomepage.plans.options.storage_option_title',
                // 할당 가능한 저장공간
                tootip: 'ohmyappHomepage.plans.options.storage_option_help_msg',
                // 10 GB
                contents: 'ohmyappHomepage.plans.test.storage_option',
            },
            {
                // 일간 트래픽
                title: 'ohmyappHomepage.plans.options.day_traffic_option_title',
                // 일일 트래픽 제한량
                tootip: 'ohmyappHomepage.plans.options.day_traffic_option_help_msg',
                // 10 GB
                contents: 'ohmyappHomepage.plans.test.day_traffic_option',
            },
            {
                // 유저 수
                title: 'ohmyappHomepage.plans.options.users_option_title',
                // 유저 수 제한
                tootip: 'ohmyappHomepage.plans.options.users_option_help_msg',
                // 300 명까지
                contents: 'ohmyappHomepage.plans.test.users_option',
            },
            // {
            //     // 사용 가이드
            //     title: 'ohmyappHomepage.plans.options.guide_option_title',
            //     // 요금제에 따른 가이드 안내
            //     tootip: 'ohmyappHomepage.plans.options.guild_option_help_msg',
            //     // 온라인
            //     contents: 'ohmyappHomepage.plans.start.guide_option',
            // },
            // {
            //     // 질의응답 채널
            //     title: 'ohmyappHomepage.plans.options.qna_option_title',
            //     // 문의대응
            //     tootip: 'ohmyappHomepage.plans.options.qna_option_help_msg',
            //     // 커뮤니티
            //     contents: 'ohmyappHomepage.plans.start.qna_option',
            // },
            // {
            //     // 스토어 출시
            //     title: 'ohmyappHomepage.plans.options.store_guide_option_title',
            //     // 스토어 출시 지원
            //     tootip: 'ohmyappHomepage.plans.options.store_guide_option_help_msg',
            //     // 가이드 제공
            //     contents: 'ohmyappHomepage.plans.start.store_guide_option',
            // },
        ],
    },
    {
        _id: {
            $oid: '',
        },
        // license: 'Lite',
        license: 'Lite',
        // Angel, 아이디어 검증을 위한
        label: 'ohmyappHomepage.subscribe.lite.label',
        // 연간 결제 시, ₩ 500,000
        caption: 'ohmyappHomepage.plans.test.caption',
        // ₩ 50,000
        price: 'ohmyappHomepage.plans.test.price',
        amount: 0,
        icon: '/assets/images/plans/plans_1Block.svg',
        button: 'ohmyappHomepage.plans.test.start_button',
        explanation: 'ohmyappHomepage.plans.test.explanation',
        options: [
            {
                disabled: true,
                // 운영 서버
                title: 'ohmyappHomepage.plans.options.manage_server_title',
                // 운영 서버의 갯수
                tootip: 'ohmyappHomepage.plans.options.manage_server_help_msg',
                // 1 개
                contents: 'ohmyappHomepage.plans.test.manage_server',
            },
            {
                // 개발 서버
                title: 'ohmyappHomepage.plans.options.devolop_server_title',
                // 개발 서버의 갯수
                tootip: 'ohmyappHomepage.plans.options.develop_server_help_msg',
                // 1 개
                contents: 'ohmyappHomepage.plans.test.develop_server',
            },
            {
                // 출시 가능한 앱
                title: 'ohmyappHomepage.plans.options.app_option_title',
                // 출시 가능한 앱의 갯수
                tootip: 'ohmyappHomepage.plans.options.app_option_help_msg',
                // 1 개
                contents: 'ohmyappHomepage.plans.test.app_option',
            },
            {
                // 저장 공간
                title: 'ohmyappHomepage.plans.options.storage_option_title',
                // 할당 가능한 저장공간
                tootip: 'ohmyappHomepage.plans.options.storage_option_help_msg',
                // 10 GB
                contents: 'ohmyappHomepage.plans.test.storage_option',
            },
            {
                // 일간 트래픽
                title: 'ohmyappHomepage.plans.options.day_traffic_option_title',
                // 일일 트래픽 제한량
                tootip: 'ohmyappHomepage.plans.options.day_traffic_option_help_msg',
                // 10 GB
                contents: 'ohmyappHomepage.plans.test.day_traffic_option',
            },
            {
                // 유저 수
                title: 'ohmyappHomepage.plans.options.users_option_title',
                // 유저 수 제한
                tootip: 'ohmyappHomepage.plans.options.users_option_help_msg',
                // 300 명까지
                contents: 'ohmyappHomepage.plans.test.users_option',
            },
            // {
            //     // 사용 가이드
            //     title: 'ohmyappHomepage.plans.options.guide_option_title',
            //     // 요금제에 따른 가이드 안내
            //     tootip: 'ohmyappHomepage.plans.options.guild_option_help_msg',
            //     // 온라인
            //     contents: 'ohmyappHomepage.plans.start.guide_option',
            // },
            // {
            //     // 질의응답 채널
            //     title: 'ohmyappHomepage.plans.options.qna_option_title',
            //     // 문의대응
            //     tootip: 'ohmyappHomepage.plans.options.qna_option_help_msg',
            //     // 커뮤니티
            //     contents: 'ohmyappHomepage.plans.start.qna_option',
            // },
            // {
            //     // 스토어 출시
            //     title: 'ohmyappHomepage.plans.options.store_guide_option_title',
            //     // 스토어 출시 지원
            //     tootip: 'ohmyappHomepage.plans.options.store_guide_option_help_msg',
            //     // 가이드 제공
            //     contents: 'ohmyappHomepage.plans.start.store_guide_option',
            // },
        ],
    },
    {
        _id: {
            $oid: '',
        },
        // license: 'Pro',
        license: 'Pro',

        // Seed, 도전을 위한
        label: 'ohmyappHomepage.subscribe.pro.label',
        caption: 'ohmyappHomepage.plans.launch.caption',
        price: 'ohmyappHomepage.plans.launch.price',
        amount: 0,
        icon: '/assets/images/plans/plans_2Block.svg',
        button: 'ohmyappHomepage.plans.launch.start_button',
        explanation: 'ohmyappHomepage.plans.launch.explanation',
        options: [
            {
                // 운영 서버
                title: 'ohmyappHomepage.plans.options.manage_server_title',
                // 운영 서버의 갯수
                tootip: 'ohmyappHomepage.plans.options.manage_server_help_msg',
                // 1 개
                contents: 'ohmyappHomepage.plans.launch.manage_server',
            },
            {
                // 개발 서버
                title: 'ohmyappHomepage.plans.options.devolop_server_title',
                // 개발 서버의 갯수
                tootip: 'ohmyappHomepage.plans.options.develop_server_help_msg',
                // 1 개
                contents: 'ohmyappHomepage.plans.launch.develop_server',
            },
            {
                // 출시 가능한 앱
                title: 'ohmyappHomepage.plans.options.app_option_title',
                // 출시 가능한 앱의 갯수
                tootip: 'ohmyappHomepage.plans.options.app_option_help_msg',
                // 2 개
                contents: 'ohmyappHomepage.plans.launch.app_option',
            },
            {
                // 저장 공간
                title: 'ohmyappHomepage.plans.options.storage_option_title',
                // 할당 가능한 저장공간
                tootip: 'ohmyappHomepage.plans.options.storage_option_help_msg',
                // 100 GB
                contents: 'ohmyappHomepage.plans.launch.storage_option',
            },
            {
                // 일간 트래픽
                title: 'ohmyappHomepage.plans.options.day_traffic_option_title',
                // 일일 트래픽 제한량
                tootip: 'ohmyappHomepage.plans.options.day_traffic_option_help_msg',
                // 100 GB
                contents: 'ohmyappHomepage.plans.launch.day_traffic_option',
            },
            {
                // 유저 수
                title: 'ohmyappHomepage.plans.options.users_option_title',
                // 유저 수 제한
                tootip: 'ohmyappHomepage.plans.options.users_option_help_msg',
                // 1만 명까지
                contents: 'ohmyappHomepage.plans.launch.users_option',
            },
            // {
            //     // 사용 가이드
            //     title: 'ohmyappHomepage.plans.options.guide_option_title',
            //     // 요금제에 따른 가이드 안내
            //     tootip: 'ohmyappHomepage.plans.options.guild_option_help_msg',
            //     // 온라인 + 1 회 오프라인
            //     contents: 'ohmyappHomepage.plans.pro.guide_option',
            // },
            // {
            //     // 질의응답 채널
            //     title: 'ohmyappHomepage.plans.options.qna_option_title',
            //     // 문의대응
            //     tootip: 'ohmyappHomepage.plans.options.qna_option_help_msg',
            //     // 1:1 온라인 문의
            //     contents: 'ohmyappHomepage.plans.pro.qna_option',
            // },
            // {
            //     // 스토어 출시
            //     title: 'ohmyappHomepage.plans.options.store_guide_option_title',
            //     // 스토어 출시 지원
            //     tootip: 'ohmyappHomepage.plans.options.store_guide_option_help_msg',
            //     // 출시 대행
            //     contents: 'ohmyappHomepage.plans.pro.store_guide_option',
            // },
        ],
    },
    {
        _id: {
            $oid: '',
        },
        // license: 'Enterprise',
        license: 'Business',

        // SeriesA, 성장을 위한
        label: 'ohmyappHomepage.subscribe.scale_up.label',
        caption: 'ohmyappHomepage.plans.scale_up.caption',
        price: 'ohmyappHomepage.plans.scale_up.price',
        amount: 0,
        icon: '/assets/images/plans/plans_3Block.svg',
        button: 'ohmyappHomepage.plans.scale_up.start_button',
        explanation: 'ohmyappHomepage.plans.scale_up.explanation',
        options: [
            {
                // 운영 서버
                title: 'ohmyappHomepage.plans.options.manage_server_title',
                // 운영 서버의 갯수
                tootip: 'ohmyappHomepage.plans.options.manage_server_help_msg',
                // 1 개
                contents: 'ohmyappHomepage.plans.scale_up.manage_server',
            },
            {
                // 개발 서버
                title: 'ohmyappHomepage.plans.options.devolop_server_title',
                // 개발 서버의 갯수
                tootip: 'ohmyappHomepage.plans.options.develop_server_help_msg',
                // n 개
                contents: 'ohmyappHomepage.plans.scale_up.develop_server',
            },
            {
                // 출시 가능한 앱
                title: 'ohmyappHomepage.plans.options.app_option_title',
                // 출시 가능한 앱의 갯수
                tootip: 'ohmyappHomepage.plans.options.app_option_help_msg',
                // n 개
                contents: 'ohmyappHomepage.plans.scale_up.app_option',
            },
            {
                // 저장 공간
                title: 'ohmyappHomepage.plans.options.storage_option_title',
                // 할당 가능한 저장공간
                tootip: 'ohmyappHomepage.plans.options.storage_option_help_msg',
                // n GB
                contents: 'ohmyappHomepage.plans.scale_up.storage_option',
            },
            {
                // 일간 트래픽
                title: 'ohmyappHomepage.plans.options.day_traffic_option_title',
                // 일일 트래픽 제한량
                tootip: 'ohmyappHomepage.plans.options.day_traffic_option_help_msg',
                // n GB
                contents: 'ohmyappHomepage.plans.scale_up.day_traffic_option',
            },
            {
                // 유저 수
                title: 'ohmyappHomepage.plans.options.users_option_title',
                // 유저 수 제한
                tootip: 'ohmyappHomepage.plans.options.users_option_help_msg',
                // n 명
                contents: 'ohmyappHomepage.plans.scale_up.users_option',
            },
            // {
            //     // 사용 가이드
            //     title: 'ohmyappHomepage.plans.options.guide_option_title',
            //     // 요금제에 따른 가이드 안내
            //     tootip: 'ohmyappHomepage.plans.options.guild_option_help_msg',
            //     // 전담 매니저 지정
            //     contents: 'ohmyappHomepage.plans.enterprise.guide_option',
            // },
            // {
            //     // 질의응답 채널
            //     title: 'ohmyappHomepage.plans.options.qna_option_title',
            //     // 문의대응
            //     tootip: 'ohmyappHomepage.plans.options.qna_option_help_msg',
            //     // 전담 지원 개발자 지정
            //     contents: 'ohmyappHomepage.plans.enterprise.qna_option',
            // },
            // {
            //     // 스토어 출시
            //     title: 'ohmyappHomepage.plans.options.store_guide_option_title',
            //     // 스토어 출시 지원
            //     tootip: 'ohmyappHomepage.plans.options.store_guide_option_help_msg',
            //     // 맞춤형 상담 및 계약 진행
            //     contents: 'ohmyappHomepage.plans.enterprise.store_guide_option',
            // },
        ],
    },
];
