// action - commone
export const ERROR = 'ERROR';

// action - account reducer
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const LOGIN_ERROR = 'LOGIN_ERROR';

// action - properties reducer
export const PROPERTIES = 'PROPERTIES';
