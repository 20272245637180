import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'src/store';

// project imports
import useAuth from 'src/hooks/use-auth';

// ==============================|| GUEST GUARD ||============================== //

type Props = {
    children: React.ReactNode;
};

function GuestGuard({ children }: Props) {
    const navigate = useNavigate();

    const { isLoggedIn } = useAuth();

    useEffect(() => {
        if (isLoggedIn) {
            navigate('/', { replace: true });
        }
    }, [isLoggedIn, navigate]);

    return <>{children}</>;
}

export default GuestGuard;
