// react
import { useEffect, useState } from 'react';
import { useSelector } from 'src/store';

// material ui
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Unstable_Grid2';
import { Box } from '@mui/material';

// project import
import Logo from 'src/components/logo';
import Iconify from 'src/components/iconify';
import { usePathname } from 'src/routes/hooks';
import Scrollbar from 'src/components/scrollbar';
import { useBoolean } from 'src/hooks/use-boolean';
import { paths } from 'src/routes/paths';
import { NavProps } from 'src/layouts/main/nav/types';
import { NAV } from 'src/layouts/config-layout';
import NavList from 'src/layouts/main/nav/mobile/nav-list';
import NavProfile from 'src/layouts/main/nav/component/nav-profile';

// context
import useAuth from 'src/hooks/use-auth';

// third party
import { useTranslation } from 'react-i18next';

// utils
import axios from 'src/utils/axios';
import { languageOptions } from 'src/utils/global-options';

// ----------------------------------------------------------------------

export default function NavMobile({ data }: NavProps) {
    const pathname = usePathname();

    const mobileOpen = useBoolean();

    const { isLoggedIn } = useAuth();
    const { t, i18n } = useTranslation();

    const [selectedLanguage, setSelectedLanguage] = useState('');

    useEffect(() => {
        if (i18n.language) {
            switch (i18n.language) {
                case 'ko-KR':
                case 'ko':
                    setSelectedLanguage('ko');
                    break;
                default:
                    setSelectedLanguage('en');
                    break;
            }
        }
    }, [i18n.language]);

    useEffect(() => {
        if (mobileOpen.value) {
            mobileOpen.onFalse();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);

    return (
        <>
            {!mobileOpen.value && (
                <IconButton onClick={mobileOpen.onTrue} sx={{ ml: 1, color: 'inherit' }}>
                    <Iconify icon="carbon:menu" />
                </IconButton>
            )}
            <Drawer
                open={mobileOpen.value}
                onClose={mobileOpen.onFalse}
                PaperProps={{
                    sx: {
                        pb: 5,
                        width: '100%',
                    },
                }}
            >
                <Scrollbar>
                    <Grid container>
                        <Grid xs={10.5} sm={11}>
                            <Logo sx={{ mx: 2.5, my: 3, width: '140px', height: '20px' }} />
                        </Grid>
                        <Grid xs={1.5} sm={1} sx={{ justifyContent: 'right' }}>
                            <Iconify
                                icon="carbon:menu"
                                onClick={mobileOpen.onFalse}
                                sx={{ color: 'inherit', justifyContent: 'right', mr: 3, my: 3 }}
                            />
                        </Grid>
                    </Grid>

                    <List component="nav" disablePadding>
                        {data.map((link) => (
                            <NavList key={link.title} item={link} />
                        ))}
                    </List>

                    <Stack spacing={1} sx={{ pb: 0, pt: 1 }} justifyContent="flex-start">
                        {!isLoggedIn ? (
                            <Button
                                variant="text"
                                href={paths.loginBackground}
                                sx={{ color: '#00bb54', justifyContent: 'left', ml: '12px' }}
                            >
                                {/* 로그인 */}
                                {t('ohmyappHomepage.main.header.login')}
                            </Button>
                        ) : (
                            <Box>
                                <NavProfile />
                            </Box>
                        )}
                    </Stack>

                    <Stack spacing={1} sx={{ px: 2, pb: 0, pt: 3 }}>
                        <FormControl fullWidth hiddenLabel size="small">
                            <Select
                                displayEmpty
                                value={selectedLanguage}
                                onChange={(event) => {
                                    const value = event.target.value;

                                    if (value === 'ko') {
                                        i18n.changeLanguage(value);
                                        axios.defaults.headers.common.language = value;
                                    } else {
                                        i18n.changeLanguage(value);
                                        axios.defaults.headers.common.language = value;
                                    }

                                    setSelectedLanguage(value);
                                }}
                            >
                                {languageOptions.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Stack>

                    {/* 다국어 */}
                    {/* <Stack spacing={1} direction="row" alignItems="center">
                        <Box
                            sx={{ cursor: 'pointer', color: selectedLanguage !== 'ko' ? '#9a9fa5' : '#1d2124' }}
                            onClick={() => languageChange('ko')}
                        >
                            <Typography variant="body2">KOR</Typography>
                        </Box>
                        <Box
                            sx={{
                                width: '1px',
                                height: '15px',
                                backgroundColor: '#ebebeb',
                            }}
                        />
                        <Box
                            sx={{ cursor: 'pointer', color: selectedLanguage !== 'en' ? '#9a9fa5' : '#1d2124' }}
                            onClick={() => languageChange('en')}
                        >
                            <Typography variant="body2">ENG</Typography>
                        </Box>
                    </Stack> */}

                    {/* <Stack spacing={1} sx={{ p: 3 }}>
                        {!isLoggedIn ? (
                            <Button variant="contained" color="inherit" href={paths.loginBackground}>
                                // 로그인
                                {t('ohmyappHomepage.main.header.login')}
                            </Button>
                        ) : (
                            <Button variant="contained" color="inherit" onClick={logout}>
                                // 로그아웃
                                {t('ohmyappHomepage.main.header.logout')}
                            </Button>
                        )}
                    </Stack> */}
                </Scrollbar>
            </Drawer>
        </>
    );
}
