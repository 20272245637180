// react
import { useState, useEffect } from 'react';

// material ui
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { useTheme } from '@mui/material/styles';
import Container from '@mui/material/Container';

// project import
import Logo from 'src/components/logo';
import { bgBlur } from 'src/theme/css';
import { paths } from 'src/routes/paths';
import { useOffSetTop } from 'src/hooks/use-off-set-top';
import { useResponsive } from 'src/hooks/use-responsive';
import { HEADER } from 'src/layouts/config-layout';
import HeaderShadow from 'src/layouts/common/header-shadow';
import NavMobile from 'src/layouts/main/nav/mobile';
import NavDesktop from 'src/layouts/main/nav/desktop';
import { navConfig, navConfigEn } from 'src/layouts/main/config-navigation';
import NavProfile from 'src/layouts/main/nav/component/nav-profile';

// context
import useAuth from 'src/hooks/use-auth';

// third party
import { useTranslation } from 'react-i18next';

// utils
import axios from 'src/utils/axios';

// ----------------------------------------------------------------------

type Props = {
    headerOnDark: boolean;
};

export default function Header({ headerOnDark }: Props) {
    const theme = useTheme();
    const offset = useOffSetTop();
    const mdUp = useResponsive('up', 'md');

    const { isLoggedIn } = useAuth();
    const { t, i18n } = useTranslation();

    const [selectedLanguage, setSelectedLanguage] = useState('');

    const languageChange = (language: string) => {
        switch (language) {
            case 'ko-KR':
            case 'ko':
                i18n.changeLanguage('ko');
                axios.defaults.headers.common.language = 'ko';
                break;
            default:
                i18n.changeLanguage('en');
                axios.defaults.headers.common.language = 'en';
                break;
        }

        setSelectedLanguage(language);
    };

    useEffect(() => {
        if (i18n.language) {
            switch (i18n.language) {
                case 'ko-KR':
                case 'ko':
                    setSelectedLanguage('ko');
                    break;
                default:
                    setSelectedLanguage('en');
                    break;
            }
        }
    }, [i18n.language]);

    return (
        <AppBar>
            <Toolbar
                disableGutters
                sx={{
                    height: {
                        xs: HEADER.H_MOBILE,
                        md: HEADER.H_DESKTOP,
                    },
                    transition: theme.transitions.create(['height', 'background-color'], {
                        easing: theme.transitions.easing.easeInOut,
                        duration: theme.transitions.duration.shorter,
                    }),
                    ...(headerOnDark && {
                        color: 'common.white',
                    }),
                    ...(offset && {
                        ...bgBlur({ color: theme.palette.background.default }),
                        color: 'text.primary',
                        height: {
                            md: HEADER.H_DESKTOP - 16,
                        },
                    }),
                }}
            >
                <Container sx={{ height: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Box sx={{ lineHeight: 0, position: 'relative', display: 'flex', alignItems: 'center' }}>
                        <Box>
                            <Logo sx={{ width: '100%', height: '100%' }} />
                        </Box>
                    </Box>

                    <Stack spacing={2} direction="row" alignItems="center" justifyContent="flex-end">
                        {/* 데스크톱 네비게이션 */}
                        <Box>
                            {mdUp && (
                                <NavDesktop
                                    data={i18n.language === 'ko' ? navConfig : navConfigEn}
                                    sx={{ ml: '70px' }}
                                />
                            )}
                        </Box>
                        {/* 로그인이 되어 있으면 프로필 보여줌 */}
                        {!isLoggedIn
                            ? mdUp && (
                                  <Button
                                      variant="text"
                                      href={paths.loginBackground}
                                      sx={{
                                          mx: '12px',
                                          color: '#00bb54',
                                          //   '&:hover': {
                                          //       backgroundColor: alpha('#00bb54', 0.1),
                                          //   },
                                      }}
                                  >
                                      {/* 로그인 */}
                                      {t('ohmyappHomepage.main.header.login')}
                                  </Button>
                              )
                            : mdUp && (
                                  <Box>
                                      <NavProfile />
                                  </Box>
                              )}
                        {/* 다국어 */}
                        {mdUp && (
                            <Stack spacing={1} direction="row" alignItems="center">
                                <Box
                                    sx={{ cursor: 'pointer', color: selectedLanguage !== 'en' ? '#9a9fa5' : '#1d2124' }}
                                    onClick={() => languageChange('en')}
                                >
                                    <Typography variant="body2">ENG</Typography>
                                </Box>
                                <Box
                                    sx={{
                                        width: '1px',
                                        height: '15px',
                                        backgroundColor: '#ebebeb',
                                    }}
                                />
                                <Box
                                    sx={{ cursor: 'pointer', color: selectedLanguage !== 'ko' ? '#9a9fa5' : '#1d2124' }}
                                    onClick={() => languageChange('ko')}
                                >
                                    <Typography variant="body2">KOR</Typography>
                                </Box>
                            </Stack>
                        )}
                    </Stack>
                    {/* 모바일 네비게이션 */}
                    {!mdUp && <NavMobile data={i18n.language === 'ko' ? navConfig : navConfigEn} />}
                </Container>
            </Toolbar>

            {offset && <HeaderShadow />}
        </AppBar>
    );
}
