import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'src/store';

// material ui
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import { alpha, styled, useTheme } from '@mui/material/styles';

// project import
import { Image } from 'src/components/image';
import ImageDialog from 'src/components/dialog/image-dialog';
import Carousel, { useCarousel, CarouselArrowIndex } from 'src/components/carousel';

// third party
import { format } from 'date-fns';
import { useDropzone } from 'react-dropzone';
import { useSnackbar } from 'notistack';

// service
import { uploadFileService } from 'src/service/global-service';

// utils
import { getImageSource } from 'src/utils/functions';
import { isArray, isString } from 'src/utils/typeof';

// assets
import CancelIcon from '@mui/icons-material/Cancel';

// type
import { ComponentMetaProps } from 'src/types/meta';
import { MODE } from 'src/utils/global-options';
import { Stack } from '@mui/material';

type ComponentProps = {
    attachments: any;
    meta: ComponentMetaProps;
    handleChange: any;
};

const DropZoneStyle = styled('div')(({ theme }) => ({
    width: 76,
    height: 76,
    fontSize: 24,
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '8px',
    backgroundColor: alpha(theme.palette.grey[300], 0.45),
    marginRight: '8px',
    '&:hover': { opacity: 0.72 },
}));

const ImageUploader = ({ attachments, meta, handleChange }: ComponentProps) => {
    const theme = useTheme();

    const { enqueueSnackbar } = useSnackbar();

    const { _documentId } = useSelector((state: any) => state.rowdatas);

    const $metaCode = meta.metaCode;
    const $collectionName = meta.collectionName;
    const $multiple = meta?.multiple;

    const [isLoading, setIsLoading] = useState(false);

    const [bindData, setBindData] = useState<any[]>([]);

    const handleRemove = useCallback(
        (file: any) => {
            const filteredItems = bindData.filter((_file) => _file !== file);
            setBindData(filteredItems);
            handleChange(filteredItems);
        },
        [bindData, handleChange],
    );

    const handleDrop = useCallback(
        (acceptedFiles: any) => {
            if (acceptedFiles.length > 10) {
                enqueueSnackbar('이미지는 한번에 10개 이하로만 등록 가능합니다.', { variant: 'warning' });
                return false;
            }

            const document: any = {};

            document.metaCode = $metaCode;
            document.collectionName = $collectionName;
            document.documentId = _documentId;
            document.component = '_file';
            document.type = 'image';

            setIsLoading(true);
            uploadFileService(document, acceptedFiles).then((response) => {
                const { files }: any = response;

                if ($multiple && files) {
                    handleChange([
                        ...bindData,
                        ...files.map((file: any) => {
                            return {
                                name: file?.fileName,
                                path: file?.webPath,
                                thumbnail: file?.thumbnail,
                            };
                        }),
                    ]);

                    setBindData((prev) => [
                        ...prev,
                        ...files.map((file: any) => {
                            return {
                                name: file.fileName,
                                path: file.webPath,
                                thumbnail: file.thumbnail,
                            };
                        }),
                    ]);

                    setIsLoading(false);
                } else {
                    handleChange(
                        ...files.map((file: any) => {
                            return file.webPath;
                        }),
                    );

                    setBindData([
                        ...files.map((file: any) => {
                            return file.webPath;
                        }),
                    ]);

                    setIsLoading(false);
                }
            });
        },
        [$metaCode, $collectionName, _documentId, $multiple, bindData, handleChange, enqueueSnackbar],
    );

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: {
            'image/*': [],
        },
        onDrop: handleDrop,
        multiple: $multiple,
    });

    useEffect(() => {
        if (!isString(attachments) && isArray(attachments)) {
            setBindData(attachments);
        } else {
            if (attachments) {
                setBindData([attachments]);
            }
        }

        return () => {
            setBindData([]);
        };
    }, [attachments]);

    return (
        <Stack direction="row" flexWrap="wrap" spacing={1}>
            <DropZoneStyle
                theme={theme}
                {...(!isLoading ? getRootProps() : {})}
                sx={{
                    ...(isDragActive && { opacity: 0.72 }),
                }}
            >
                <input {...getInputProps()} />

                {/* <Button color="inherit" variant="outlined" size="large" sx={{ p: 2.25, border: 'none' }}> */}
                <CardMedia component="img" sx={{ width: 24, height: 24 }} image="/assets/icons/ic-camera-fill.png" />
                {/* </Button> */}
            </DropZoneStyle>
            {isLoading && (
                <Box sx={{ p: 2.25 }}>
                    <CircularProgress />
                </Box>
            )}
            {bindData?.map((file) => {
                const { path, webPath, thumbnail } = file;
                const key = isString(file) ? file : path ?? webPath;

                return (
                    <Box
                        key={key}
                        sx={{
                            p: 0,
                            m: 0,
                            width: 76,
                            height: 76,
                            borderRadius: 0.25,
                            overflow: 'hidden',
                            position: 'relative',
                        }}
                    >
                        <Image
                            src={isString(file) ? getImageSource(file) : getImageSource(thumbnail ?? path)}
                            sx={{ borderRadius: '8px' }}
                            ratio="1/1"
                        />
                        <Box sx={{ top: 6, right: 6, position: 'absolute' }}>
                            <IconButton
                                size="small"
                                onClick={() => handleRemove(file)}
                                sx={{
                                    p: '1px',
                                    color: 'common.white',
                                    bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
                                    '&:hover': {
                                        bgcolor: (theme) => alpha(theme.palette.grey[900], 0.48),
                                    },
                                }}
                            >
                                <CancelIcon />
                            </IconButton>
                        </Box>
                    </Box>
                );
            })}
        </Stack>
    );
};

export default ImageUploader;
