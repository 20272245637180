import { lazy } from 'react';

// utils
import AuthGuard from 'src/utils/guard/auth-gaurd';

// ----------------------------------------------------------------------

const AppMakerIndex = lazy(() => import('src/pages/app-maker'));
const AppMakerComplete = lazy(() => import('src/pages/app-maker/app-maker-complete'));

// ----------------------------------------------------------------------

export const appMakerRoutes = [
    {
        path: 'app-maker',
        children: [
            {
                element: (
                    <AuthGuard>
                        <AppMakerIndex />
                    </AuthGuard>
                ),
                index: true,
            },
            {
                path: 'complete',
                element: (
                    <AuthGuard>
                        <AppMakerComplete />
                    </AuthGuard>
                ),
            },
        ],
    },
];
