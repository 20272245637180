import { lazy } from 'react';

// ----------------------------------------------------------------------

const PlansPage = lazy(() => import('src/pages/plans'));

// ----------------------------------------------------------------------

export const plansRoutes = [
    {
        path: 'plans',
        children: [{ element: <PlansPage />, index: true }],
    },
];
