// material ui
import Link from '@mui/material/Link';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Box } from '@mui/material';

// project import
import Iconify from 'src/components/iconify';
import { RouterLink } from 'src/routes/components';
import SvgColor from 'src/components/svg-color';
import { StyledNavItem } from 'src/layouts/main/nav/mobile/styles';

// types
import { NavItemProps } from 'src/layouts/main/nav/types';

// third party
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function NavItem({ item, open, active, externalLink, ...other }: NavItemProps) {
    const { t } = useTranslation();
    const renderContent = (
        <StyledNavItem active={active} {...other}>
            <ListItemIcon> {item.icon} </ListItemIcon>

            <Box display="flex" alignItems="center">
                {/* 헤더 메뉴 다국어 */}
                <ListItemText disableTypography primary={t(item.title)} />

                {externalLink && (
                    <SvgColor
                        src="/assets/icons/ic_external.svg"
                        sx={{ width: 16, height: 16, color: '#9a9fa5', ml: '8px' }}
                    />
                )}
            </Box>

            {!!item.children && (
                <Iconify width={16} icon={open ? 'carbon:chevron-down' : 'carbon:chevron-right'} sx={{ ml: 1 }} />
            )}
        </StyledNavItem>
    );

    // ExternalLink
    if (externalLink) {
        return (
            <Link href={item.path} target="_blank" rel="noopener" underline="none">
                {renderContent}
            </Link>
        );
    }

    // Has child
    if (item.children) {
        return renderContent;
    }

    // Default
    return (
        <Link component={RouterLink} href={item.path} underline="none">
            {renderContent}
        </Link>
    );
}
