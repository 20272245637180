import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import CompactLayout from 'src/layouts/compact';
import { SplashScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

const ComingSoonPage = lazy(() => import('src/pages/coming-soon'));
const MaintenancePage = lazy(() => import('src/pages/maintenance'));
const Page404 = lazy(() => import('src/pages/404'));
const Page500 = lazy(() => import('src/pages/500'));

// ----------------------------------------------------------------------

export const errorRoutes = [
    {
        element: (
            <CompactLayout>
                <Suspense fallback={<SplashScreen />}>
                    <Outlet />
                </Suspense>
            </CompactLayout>
        ),
        children: [
            { path: 'coming-soon', element: <ComingSoonPage /> },
            { path: 'maintenance', element: <MaintenancePage /> },
            { path: 'error', element: <Page500 /> },
            { path: '404', element: <Page404 /> },
        ],
    },
];
