import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { SplashScreen } from 'src/components/loading-screen';
import AuthTermsBackgroundLayout from 'src/layouts/auth/terms-background';

// ----------------------------------------------------------------------

// ohmyapp
const TermsPage = lazy(() => import('src/pages/auth/ohmyapp/terms'));
const PrivacyPolicyPage = lazy(() => import('src/pages/auth/ohmyapp/privacy-policy'));
const OutsourcingPage = lazy(() => import('src/pages/app-maker/outsourcing'));

// ----------------------------------------------------------------------

export const authPublicRoutes = [
    {
        path: 'auth-public',
        element: (
            <Suspense fallback={<SplashScreen />}>
                <Outlet />
            </Suspense>
        ),
        children: [
            {
                path: 'terms',
                element: (
                    <AuthTermsBackgroundLayout>
                        <TermsPage />
                    </AuthTermsBackgroundLayout>
                ),
            },
            {
                path: 'privacy-policy',
                element: (
                    <AuthTermsBackgroundLayout>
                        <PrivacyPolicyPage />
                    </AuthTermsBackgroundLayout>
                ),
            },
            // 유저 정보가 치환되기 때문에 로그인 정보 필요함
            {
                path: 'outsourcing',
                element: (
                    <AuthTermsBackgroundLayout>
                        <OutsourcingPage />
                    </AuthTermsBackgroundLayout>
                ),
            },
        ],
    },
];
