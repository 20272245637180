// third-party
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import accountReducer from 'src/store/slices/account';
import appmakerReducer from 'src/store/slices/app-maker';
import homeReducer from 'src/store/slices/home';
import dialogReducer from 'src/store/slices/dialog';
import communityReducer from 'src/store/slices/community';
import noticeReducer from 'src/store/slices/notice';
import doctimelineReducer from 'src/store/slices/doctimeline';
import rowdatasReducer from 'src/store/slices/row-datas';
import couponReducer from 'src/store/slices/coupon';
import payemntReducer from 'src/store/slices/payment';
import regularPayemntReducer from 'src/store/slices/regularPayment';

const reducer = combineReducers({
    account: accountReducer,
    appmaker: appmakerReducer,
    home: homeReducer,
    dialog: dialogReducer,
    community: communityReducer,
    notice: noticeReducer,
    doctimeline: doctimelineReducer,
    rowdatas: rowdatasReducer,
    coupon: couponReducer,
    // 토스 위젯에서 redirect후에도 state 유지
    payment: persistReducer(
        {
            key: 'payment',
            storage,
        },
        payemntReducer,
    ),
    // 토스 위젯에서 redirect후에도 state 유지
    regularPayment: persistReducer(
        {
            key: 'regularPayment',
            storage,
        },
        regularPayemntReducer,
    ),
});

export default reducer;
