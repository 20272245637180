import { lazy } from 'react';

// utils
import AuthGuard from 'src/utils/guard/auth-gaurd';

// ----------------------------------------------------------------------

const AppInfoPage = lazy(() => import('src/pages/app-info'));

// ----------------------------------------------------------------------

export const appInfoRoutes = [
    {
        path: 'app-info/:uid',
        children: [
            {
                element: (
                    <AuthGuard>
                        <AppInfoPage />
                    </AuthGuard>
                ),
                index: true,
            },
        ],
    },
];
