// material ui
import Stack from '@mui/material/Stack';

// project import
import NavList from 'src/layouts/main/nav/desktop/nav-list';

// types
import { NavProps } from 'src/layouts/main/nav/types';

// ----------------------------------------------------------------------

export default function NavDesktop({ data, sx }: NavProps) {
    return (
        <Stack
            component="nav"
            direction="row"
            spacing={5}
            sx={{
                height: 1,
                ...sx,
            }}
        >
            {data.map((link) => (
                <NavList key={link.title} item={link} />
            ))}
        </Stack>
    );
}
