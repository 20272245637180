// third-party
import _ from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { format } from 'date-fns';
import { useSnackbar } from 'notistack';

// service
import { listDocument } from 'src/service/global-service';

// utils
import { dispatch } from 'src/store';

const initialState = {
    billingClientKey: null,
    billingSecretKey: null,

    selectedPlan: null,
    isFreeTrial: false,
    isCardChange: false,
    currentMembership: null,
    isApplyCoupon: false,
    applyCouponId: null,
    couponAppliedPrice: null,
};

const regularPayment = createSlice({
    name: 'regularPayment',
    initialState,
    reducers: {
        setInitialState: (state) => {
            // billingClientKey와 billingSecretKey는 초기화 대상에서 제외

            state.selectedPlan = null;
            state.isFreeTrial = false;
            state.isCardChange = false;
            state.currentMembership = null;
            state.isApplyCoupon = false;
            state.applyCouponId = null;
            state.couponAppliedPrice = null;
        },
        setBillingApiKey(state, action) {
            const { key, secretKey } = action.payload;

            state.billingClientKey = key;
            state.billingSecretKey = secretKey;
        },
        setIsCardChange(state, action) {
            const { isCardChange, currentMembership } = action.payload;

            state.isCardChange = isCardChange ?? null;
            state.currentMembership = currentMembership ?? null;
        },
        setSelectedPlan(state, action) {
            const { isFreeTrial, plan } = action.payload;

            state.isFreeTrial = isFreeTrial ?? false;
            state.selectedPlan = plan;
        },
        setApplyCoupon(state, action) {
            const { isApplyCoupon, applyCouponId, couponAppliedPrice } = action.payload;

            state.isApplyCoupon = isApplyCoupon;
            state.applyCouponId = applyCouponId ?? null;
            state.couponAppliedPrice = couponAppliedPrice;
        },
    },
});

// Reducer
export default regularPayment.reducer;

export const { setInitialState, setIsCardChange, setSelectedPlan, setApplyCoupon } = regularPayment.actions;

export function getBillingApiKey() {
    return async () => {
        const document: any = {};

        document.metaCode = '_apiKey';
        document.collectionName = '_apiKey';

        const documentJson: any = {};
        documentJson.filters = [{ fieldName: 'code', method: 'eq', value: 'tossBillingPayment' }];
        document.documentJson = documentJson;

        await listDocument(document).then(({ data }: any) => {
            dispatch(regularPayment.actions.setBillingApiKey(data[0]));
        });
    };
}
