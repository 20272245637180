// Returns if a value is a string
export function isString(value: any) {
    return typeof value === 'string' || value instanceof String;
}

// Returns if a value is really a number
export function isNumber(value: any) {
    return typeof value === 'number' && Number.isFinite(value);
}

// Returns if a value is an array
export function isArray(value: any) {
    return value && typeof value === 'object' && value.constructor === Array;
}

// Returns if a value is a function
export function isFunction(value: any) {
    return typeof value === 'function';
}

// Returns if a value is an object
export function isObject(value: any) {
    return value && typeof value === 'object' && value.constructor === Object;
}

// Returns if a value is null
export function isNull(value: any) {
    return value === null;
}

// Returns if a value is empty
export function isEmpty(value: any) {
    return value === '';
}

// Returns if a value is empty
export function isEmptyObject(value: any) {
    return Object.keys(value).length === 0 && value.constructor === Object;
}

// Returns if a value is undefined
export function isUndefined(value: any) {
    return typeof value === 'undefined';
}

// Returns if a value is undefined
export function isNaNString(value: any) {
    return value === 'NaN';
}

// Returns if a value is a boolean
export function isBoolean(value: any) {
    return typeof value === 'boolean';
}

// Returns if a value is a regexp
export function isRegExp(value: any) {
    return value && typeof value === 'object' && value.constructor === RegExp;
}

// Returns if value is an error object
export function isError(value: any) {
    return value instanceof Error && typeof value.message !== 'undefined';
}

// Returns if value is a date object
export function isDate(value: any) {
    return value instanceof Date && !Number.isNaN(value);
}

// Returns if a Symbol
export function isSymbol(value: any) {
    return typeof value === 'symbol';
}

// Returns if a object in key
export function isOwnProperty(obj: any, value: any) {
    return Object.prototype.hasOwnProperty.call(obj, value);
}

// Returns if a value is Mobile
export function isMobile() {
    if (window) {
        return window.matchMedia(`(max-width: 767px)`).matches;
    }
    return false;
}

// Returns if a value is Md screen
export function isMdScreen() {
    if (window) {
        return window.matchMedia(`(max-width: 1199px)`).matches;
    }
    return false;
}

// Returns if a Infinity in key
export function isInfinity(value: any) {
    return value === Infinity;
}
