// react
import { useDispatch, useSelector } from 'src/store';

// material ui
import Paper, { PaperProps } from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

// project import
import getVariant from 'src/sections/examples/animate-view/get-variant';

// reducer
import { closeDialog } from 'src/store/slices/dialog';

// third party
import { m, AnimatePresence } from 'framer-motion';

function PaperComonent({ children, ...props }: PaperProps, animate: any) {
    return (
        <m.div {...getVariant(animate)}>
            <Paper {...props}>{children}</Paper>
        </m.div>
    );
}

function CustomDialog() {
    const dispatch = useDispatch();

    const { open, title, context, agreeText, disagreeText, onAgree, onDisagree, maxWidth, scroll, animate } =
        useSelector((state: any) => state.dialog);

    return (
        <AnimatePresence>
            {open && (
                <Dialog
                    maxWidth={maxWidth}
                    scroll={scroll}
                    open={open}
                    onClose={() => dispatch(closeDialog())}
                    PaperComponent={(props: PaperProps) => PaperComonent({ ...props }, animate)}
                >
                    {title && <DialogTitle id="alert-dialog-title">{title}</DialogTitle>}
                    {context && <DialogContent sx={{ whiteSpace: 'pre-line' }}>{context}</DialogContent>}
                    <DialogActions>
                        {/* Disagree */}
                        {/* Disagree를 입력하지 않으면 alert dialog, 입력하면 confrim dialog */}
                        {disagreeText && <Button onClick={() => dispatch(closeDialog())}>{disagreeText}</Button>}
                        {/* Agree button */}
                        {agreeText && (
                            <Button variant="contained" onClick={onAgree} autoFocus>
                                {agreeText}
                            </Button>
                        )}
                    </DialogActions>
                </Dialog>
            )}
        </AnimatePresence>
    );
}

export default CustomDialog;
