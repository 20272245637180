import { lazy, Suspense } from 'react';
import { Outlet, Navigate, useRoutes } from 'react-router-dom';

import MainLayout from 'src/layouts/main';
import { SplashScreen } from 'src/components/loading-screen';

// ohmy
import { communityRoutes } from 'src/routes/sections/community';
import { accountRoutes } from 'src/routes/sections/account';
import { appMakerRoutes } from 'src/routes/sections/appMaker';
import { plansRoutes } from 'src/routes/sections/plans';
import { faqRoutes } from 'src/routes/sections/faq';
import { appInfoRoutes } from 'src/routes/sections/appInfo';
import { serviceRoutes } from 'src/routes/sections/service';
import { noticeRoutes } from 'src/routes/sections/notice';
import { paymentPageRoutes } from 'src/routes/sections/paymentPage';

import { authRoutes } from './auth';
import { authPublicRoutes } from './auth-public';
import { errorRoutes } from './error';
import { commonRoutes } from './common';
import { careerRoutes } from './career';
import { travelRoutes } from './travel';
import { marketingRoutes } from './marketing';
import { eLearningRoutes } from './elearning';
import { eCommerceRoutes } from './ecommerce';
import { componentsRoutes } from './components';

// ----------------------------------------------------------------------

const IndexPage = lazy(() => import('src/pages/home'));
const SupportPage = lazy(() => import('src/pages/support'));

export default function Router() {
    return useRoutes([
        {
            element: (
                <MainLayout>
                    <Suspense fallback={<SplashScreen />}>
                        <Outlet />
                    </Suspense>
                </MainLayout>
            ),
            children: [
                { element: <IndexPage />, index: true },
                { path: 'support', element: <SupportPage /> },

                // 오마이앱 Routes
                ...communityRoutes,
                ...accountRoutes,
                ...appMakerRoutes,
                ...plansRoutes,
                ...faqRoutes,
                ...appInfoRoutes,
                ...serviceRoutes,
                ...noticeRoutes,

                // 샘플 Routes
                ...marketingRoutes,
                ...travelRoutes,
                ...careerRoutes,
                ...eLearningRoutes,
                ...eCommerceRoutes,
                ...componentsRoutes,
            ],
        },

        ...paymentPageRoutes,
        ...authRoutes,
        ...authPublicRoutes,
        ...errorRoutes,
        ...commonRoutes,

        { path: '*', element: <Navigate to="/404" replace /> },
    ]);
}
