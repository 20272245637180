import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// context
import useAuth from 'src/hooks/use-auth';

// project import
import { paths } from 'src/routes/paths';

// ==============================|| AUTH GUARD ||============================== //

// /**
//  * Authentication guard for routes
//  * @param {PropTypes.node} children children element/node
//  */
const AuthGuard = ({ children }: any) => {
    const navigate = useNavigate();

    const { isLoggedIn } = useAuth();

    useEffect(() => {
        if (!isLoggedIn) {
            navigate(paths.loginBackground, { replace: true });
        }
    }, [isLoggedIn, navigate]);

    return children;
};

export default AuthGuard;
