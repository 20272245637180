import { lazy } from 'react';

// ----------------------------------------------------------------------

const ServicePage = lazy(() => import('src/pages/service'));

// ----------------------------------------------------------------------

export const serviceRoutes = [
    {
        path: 'service',
        children: [{ element: <ServicePage />, index: true }],
    },
];
