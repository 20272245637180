// third-party
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    _documentId: null,
    _updatedDatas: {},
};

const rowdatas = createSlice({
    name: 'rowdatas',
    initialState,
    reducers: {
        setInitialState: () => initialState,
        setDocumentId(state, action) {
            state._documentId = action.payload;
        },
        setUpdatedDatas(state, action) {
            const { key, value } = action.payload;

            state._updatedDatas = {
                ...state._updatedDatas,
                [key]: value,
            };
        },
    },
});

// Reducer
export default rowdatas.reducer;

export const { setInitialState, setDocumentId, setUpdatedDatas } = rowdatas.actions;
