// material ui
import { IconButton } from '@mui/material';

// project import
import Iconify from 'src/components/iconify';

// third party
import { useSnackbar } from 'notistack';

export default function SnackbarAction({ snackbarId }: any) {
    const { closeSnackbar } = useSnackbar();

    return (
        <IconButton onClick={() => closeSnackbar(snackbarId)}>
            <Iconify icon="material-symbols:close" color="#fff" />
        </IconButton>
    );
}
