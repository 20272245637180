import { lazy } from 'react';

import { Outlet } from 'react-router-dom';

// ----------------------------------------------------------------------

// base
const NoticeBase = lazy(() => import('src/pages/notice/notice-base'));

// page
const NoticePage = lazy(() => import('src/pages/notice/notice'));
const NoticeDetail = lazy(() => import('src/pages/notice/notice-detail'));

// ----------------------------------------------------------------------

export const noticeRoutes = [
    {
        path: 'notice',
        element: (
            <NoticeBase>
                <Outlet />
            </NoticeBase>
        ),
        children: [
            { element: <NoticePage />, index: true },
            { path: 'detail/:oid', element: <NoticeDetail /> },
        ],
    },
];
