// ----------------------------------------------------------------------
export const GOOGLE_MAP_API = process.env.NEXT_PUBLIC_MAP_API;
export const IMP_CODE = process.env.REACT_APP_IMP_CODE;
export const WIDGET_CLIENT_KEY = process.env.REACT_APP_WIDGET_CLIENT_KEY;
export const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;

// export const baseUrl = '';
// export const baseUrl = 'http://localhost:8080/';
// export const baseUrl = 'https://kitschers.ohmyapp.io/';
export const baseUrl = 'https://ohmyapp.ohmyapp.io/';
// export const baseUrl = 'https://ohmyappDev.ohmyapp.io/';
// export const baseUrl = 'https://ohmyapp-aws.ohmyapp.io/';
// export const baseUrl = 'https://withoutcode.ohmyapp.io/';
// export const baseUrl = 'https://kinggunny.ohmyapp.io/';
// export const baseUrl = 'https://withoutcode.ohmyapp.io/';
