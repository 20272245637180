// action - state management
import { LOGIN, LOGIN_ERROR, LOGOUT } from 'src/store/actions';

// ==============================|| ACCOUNT REDUCER ||============================== //

const initialState = {
    loginError: false,
    isLoggedIn: false,
    userInfo: null,
};

// eslint-disable-next-line default-param-last
const accountReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case LOGIN: {
            const { userInfo } = action.payload;

            return {
                ...state,
                loginError: false,
                isLoggedIn: true,
                userInfo,
            };
        }
        case LOGOUT: {
            return {
                ...state,
                loginError: false,
                isLoggedIn: false,
                userInfo: null,
            };
        }
        case LOGIN_ERROR: {
            return {
                ...state,
                loginError: true,
                isLoggedIn: false,
                userInfo: null,
            };
        }
        default: {
            return { ...state };
        }
    }
};

export default accountReducer;
