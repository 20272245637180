import { createContext, useEffect, useMemo, useCallback, useReducer } from 'react';
import { useNavigate } from 'react-router-dom';

// third-party
import { useSnackbar } from 'notistack';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/analytics';
import { getAuth, signInWithPopup, OAuthProvider, updateProfile } from 'firebase/auth';

// project import
import { baseUrl } from 'src/config-global';

// action - state management
import { LOGIN, LOGIN_ERROR, LOGOUT } from 'src/store/actions';

// context
import useProperties from 'src/hooks/use-properties';

// reducer
import { setAuthenticated } from 'src/store/slices/account';
import accountReducer from 'src/store/reducer/account-reducer';

// service
import {
    socialLogin,
    getUserInfo,
    loginByEmail as loginByEmailService,
    registerByEmailWithoutAuthCode,
    sendResetPasswordMail,
    verifyCodeConfirm as verifyCodeConfirmService,
    passwordChange as passwordChangeService,
} from 'src/service/auth-service';

// utils
import axios from 'src/utils/axios';
import { isUndefined, isEmpty } from 'src/utils/typeof';

const initialState = {
    loginError: false,
    isLoggedIn: false,
    userInfo: null,
};

// 추후 env 처리
const FIREBASE_API = {
    apiKey: 'AIzaSyB_LvQlSbty5wccUCD6jVNajdyaY_4x6IU',
    authDomain: 'ohmyapp-58d39.firebaseapp.com',
    projectId: 'ohmyapp-58d39',
    storageBucket: 'ohmyapp-58d39.appspot.com',
    messagingSenderId: '30343391663',
    appId: '1:30343391663:web:0cbb8f277f08a9d85ca75a',
    measurementId: 'G-QG88RTZJG8',
};

// firebase initialize
if (!firebase.apps.length) {
    firebase.initializeApp(FIREBASE_API);
    firebase.analytics();
}

const setSession = (accessToken: string | null) => {
    if (accessToken) {
        localStorage.setItem('accessToken', accessToken);
        axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    } else {
        localStorage.removeItem('accessToken');
        delete axios.defaults.headers.common.Authorization;
    }
};
// ==============================|| FIREBASE CONTEXT & PROVIDER ||============================== //

type FirebaseContextType = {
    loginError: boolean;
    isLoggedIn: boolean;
    userInfo: any;
    // 메소드와 상태에 대한 타입 정의
    firebaseRegister: (email: string, password: string) => Promise<firebase.auth.UserCredential>;
    firebaseEmailPasswordSignIn: (email: string, password: string) => Promise<firebase.auth.UserCredential>;
    loginByEmail: any;
    firebaseGoogleSignIn: any;
    firebaseAppleSignIn: any;
    logout: any;
    register: any;
    updateUserInfo: any;
    sendResetPasswordVerifyCodeMail: any;
    verifyCodeConfirm: any;
    passwordChange: any;
};

const FirebaseContext = createContext<FirebaseContextType | null>(null);

type Props = {
    children: React.ReactNode;
};

export function FirebaseProvider({ children }: Props) {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const { isNickName, defaultUserImage } = useProperties();

    const [state, dispatch] = useReducer(accountReducer, initialState, (initial) => {
        const persistedState = localStorage.getItem('firebaseState');
        return persistedState ? JSON.parse(persistedState) : initial;
    });

    useEffect(() => {
        localStorage.setItem('firebaseState', JSON.stringify(state));
    }, [state]);

    useEffect(() => {
        const init = async () => {
            try {
                const existAccessToken = window.localStorage.getItem('accessToken');
                if (existAccessToken) {
                    const document: any = {};
                    // document.token = existAccessToken;
                    document.isShortToken = true;

                    const data = await getUserInfo(document);

                    const { accessToken, code, message } = data;

                    if ((!isUndefined(accessToken) || !isEmpty(accessToken)) && code === '1') {
                        setSession(accessToken);
                        dispatch({
                            type: LOGIN,
                            payload: {
                                userInfo: JSON.parse(message), // 로그인 성공시 유저 정보가 들어옴
                            },
                        });
                    } else if ((!isUndefined(accessToken) || !isEmpty(accessToken)) && code === '2') {
                        setSession(accessToken);
                        dispatch({
                            type: LOGIN,
                            payload: {
                                userInfo: JSON.parse(message), // 로그인 성공시 유저 정보가 들어옴
                            },
                        });
                    } else {
                        setSession(null);
                        dispatch({ type: LOGOUT });
                    }
                }
            } catch (err) {
                console.error(`Auth provider Exception :${err}`);
            }
        };

        init();
    }, [dispatch]);

    const loginByEmail = useCallback(
        async (email: string, password: string) => {
            const document: any = {};

            document.loginId = email;
            document.loginPw = password;
            document.isShortToken = true;

            const data = await loginByEmailService(document);

            const { accessToken, code, message } = data;

            if ((!isUndefined(accessToken) || !isEmpty(accessToken)) && code === '1') {
                setSession(accessToken);
                dispatch({
                    type: LOGIN,
                    payload: {
                        userInfo: JSON.parse(message), // 로그인 성공시 유저 정보가 들어옴
                    },
                });

                return code;
            } else {
                setSession(null);
                dispatch({ type: LOGIN_ERROR });

                return code;
            }
        },
        [dispatch],
    );

    const firebaseEmailPasswordSignIn = (email: string, password: string) =>
        firebase.auth().signInWithEmailAndPassword(email, password);

    const firebaseGoogleSignIn = useCallback(async () => {
        // Google OAuthProvider 생성
        const provider = new firebase.auth.GoogleAuthProvider();

        return firebase
            .auth()
            .signInWithPopup(provider)
            .then(async (result) => {
                const user = result.user;

                const document: any = {
                    userId: '',
                    email: '',
                    profile: '',
                    name: '',
                    socialType: 'google',
                    // ohmyapp _properties appOption.register
                    registerType: '',
                };

                document.userId = user?.uid ?? '';
                document.email = user?.email ?? '';

                // ohmyapp _properties appOption.defaultUserImage
                document.profile = user?.photoURL ?? defaultUserImage;
                document.name = user?.displayName ?? 'Unknown';

                // ohmyapp _properties isNickname 속성에 따라 부여하는 로직 필요
                const data = await socialLogin(document, isNickName);

                const { accessToken, code, message } = data;

                if ((!isUndefined(accessToken) || !isEmpty(accessToken)) && code === '1') {
                    setSession(accessToken);
                    dispatch({
                        type: LOGIN,
                        payload: {
                            userInfo: JSON.parse(message), // 로그인 성공시 유저 정보가 들어옴
                        },
                    });
                } else if ((!isUndefined(accessToken) || !isEmpty(accessToken)) && code === '2') {
                    setSession(accessToken);
                    dispatch({
                        type: LOGIN,
                        payload: {
                            userInfo: JSON.parse(message), // 로그인 성공시 유저 정보가 들어옴
                        },
                    });
                } else {
                    setSession(null);
                    dispatch({ type: LOGOUT });
                }
            })
            .catch((error) => {
                // 로그인 실패 후 로직
                console.error(error);
            });
    }, [dispatch, isNickName, defaultUserImage]);

    const firebaseAppleSignIn = useCallback(async () => {
        const auth = getAuth();
        const provider = new OAuthProvider('apple.com');
        provider.addScope('email');
        provider.addScope('name');

        try {
            const result = await signInWithPopup(auth, provider);
            const user = result.user;

            const document: any = {
                userId: '',
                email: '',
                profile: '',
                name: '',
                socialType: 'apple',
                // ohmyapp _properties appOption.register
                registerType: '',
            };

            document.userId = user?.uid ?? '';
            document.email = user?.email ?? '';

            // ohmyapp _properties appOption.defaultUserImage
            document.profile = null;
            document.name = user?.displayName ?? 'Unknown';

            // ohmyapp _properties isNickname 속성에 따라 부여하는 로직 필요
            const data = await socialLogin(document, true);

            const { accessToken, code, message } = data;

            if ((!isUndefined(accessToken) || !isEmpty(accessToken)) && code === '1') {
                setSession(accessToken);
                dispatch({
                    type: LOGIN,
                    payload: {
                        userInfo: JSON.parse(message), // 로그인 성공시 유저 정보가 들어옴
                    },
                });
            } else if ((!isUndefined(accessToken) || !isEmpty(accessToken)) && code === '2') {
                setSession(accessToken);
                dispatch({
                    type: LOGIN,
                    payload: {
                        userInfo: JSON.parse(message), // 로그인 성공시 유저 정보가 들어옴
                    },
                });
            } else {
                setSession(null);
                dispatch({ type: LOGOUT });
            }
        } catch (error) {
            // 로그인 실패 후 로직
            console.error(error);
        }
    }, [dispatch]);

    const firebaseRegister = async (email: string, password: string) =>
        firebase.auth().createUserWithEmailAndPassword(email, password);

    const register = useCallback(
        async (document: any) => {
            const response = await registerByEmailWithoutAuthCode(document);

            const { accessToken, code, message } = response;

            if ((!isUndefined(accessToken) || !isEmpty(accessToken)) && code === '1') {
                setSession(accessToken);
                dispatch({
                    type: LOGIN,
                    payload: {
                        userInfo: JSON.parse(message), // 로그인 성공시 유저 정보가 들어옴
                    },
                });

                return code;
            } else {
                enqueueSnackbar(message, { variant: 'warning' });

                setSession(null);
                dispatch({ type: LOGIN_ERROR });

                return code;
            }
        },
        [dispatch, enqueueSnackbar],
    );

    const updateUserInfo = useCallback(async () => {
        const existAccessToken = window.localStorage.getItem('accessToken');
        if (existAccessToken) {
            const document: any = {};
            // document.token = existAccessToken;
            document.isShortToken = true;

            const data = await getUserInfo(document);

            const { accessToken, code, message } = data;

            if ((!isUndefined(accessToken) || !isEmpty(accessToken)) && code === '1') {
                setSession(accessToken);
                dispatch({
                    type: LOGIN,
                    payload: {
                        userInfo: JSON.parse(message), // 로그인 성공시 유저 정보가 들어옴
                    },
                });
            } else if ((!isUndefined(accessToken) || !isEmpty(accessToken)) && code === '2') {
                setSession(accessToken);
                dispatch({
                    type: LOGIN,
                    payload: {
                        userInfo: JSON.parse(message), // 로그인 성공시 유저 정보가 들어옴
                    },
                });
            } else {
                setSession(null);
                dispatch({ type: LOGOUT });
            }
        }
    }, [dispatch]);

    const logout = useCallback(() => {
        const auth = getAuth();

        if (auth.currentUser) {
            firebase.auth().signOut();
        }

        setSession(null);

        navigate('/', { replace: true });
        dispatch({ type: LOGOUT });
    }, [dispatch, navigate]);

    const sendResetPasswordVerifyCodeMail = useCallback(async (email: string) => {
        const document: any = {};
        document.email = email;

        const response = await sendResetPasswordMail(document);
        return response;
    }, []);

    const verifyCodeConfirm = useCallback(async (email: string, code: string) => {
        const document: any = {};
        document.email = email;
        document.authCode = code;

        const response = await verifyCodeConfirmService(document);
        return response;
    }, []);

    const passwordChange = useCallback(async (userUniqueId: string, password: string) => {
        // const documentJson: any = {};
        // documentJson._id = userUniqueId;
        // documentJson.password = password;
        // const document: any = {};
        // document.metaCode = '_users';
        // document.collectionName = '_users';
        // document.documentJson = documentJson;
        // const response = await passwordChangeService(document);
        // return response;
    }, []);

    const value = useMemo<FirebaseContextType>(
        () => ({
            ...state,
            loginError: state.loginError,
            isLoggedIn: state.isLoggedIn,
            userInfo: state.userInfo,
            firebaseRegister,
            firebaseEmailPasswordSignIn,
            loginByEmail,
            firebaseGoogleSignIn,
            firebaseAppleSignIn,
            logout,
            register,
            updateUserInfo,
            sendResetPasswordVerifyCodeMail,
            verifyCodeConfirm,
            passwordChange,
        }),
        [
            state,
            loginByEmail,
            logout,
            register,
            firebaseGoogleSignIn,
            firebaseAppleSignIn,
            updateUserInfo,
            sendResetPasswordVerifyCodeMail,
            verifyCodeConfirm,
            passwordChange,
        ],
    );

    return <FirebaseContext.Provider value={value}>{children}</FirebaseContext.Provider>;
}

export default FirebaseContext;
