// third-party
import { createSlice } from '@reduxjs/toolkit';

// service
import { listDocument } from 'src/service/global-service';

// utils
import { dispatch } from 'src/store';

const initialState = {
    subscriptionProducts: [],
};

const home = createSlice({
    name: 'home',
    initialState,
    reducers: {
        setInitialState: () => initialState,
        setSubscriptionProducts(state, action) {
            state.subscriptionProducts = action.payload ?? [];
        },
    },
});

// Reducer
export default home.reducer;

export const { setInitialState, setSubscriptionProducts } = home.actions;

export function getSubscriptionProducts() {
    return async () => {
        const document: any = {};

        document.metaCode = 'product';
        document.collectionName = 'product';

        const documentJson: any = {};
        documentJson.filters = [
            { fieldName: 'promotion', method: 'eq', value: '' },
            { fieldName: 'useYN', method: 'eq', value: true },
            { fieldName: 'name', method: 'in', value: ['Lite', 'Pro', 'Business'] },
        ];
        document.documentJson = documentJson;

        await listDocument(document).then(({ data }: any) => {
            dispatch(home.actions.setSubscriptionProducts(data));
        });
    };
}
