import { createContext, useEffect, useMemo, useCallback, useReducer } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'src/store';

// project import
import { SplashScreen } from 'src/components/loading-screen';

// action - state management
import { PROPERTIES, ERROR } from 'src/store/actions';

// reducer
import propertiesReducer from 'src/store/reducer/properties-reducer';

// utils
import { listDocument } from 'src/service/global-service';
import { isUndefined, isEmpty } from 'src/utils/typeof';

const initialState = {
    isInitialized: false,
    webProperties: null,
    appProperties: null,
    commonProperties: null,
    socket: null,
    isNickName: true,
    defaultUserImage: '',
};

// ==============================|| PROPERTIES CONTEXT & PROVIDER ||============================== //

type PropertiesContextType = {
    isInitialized: boolean;
    webProperties: any;
    appProperties: any;
    commonProperties: any;
    socket: any;
    isNickName: boolean;
    defaultUserImage: string;
};

const PropertiesContext = createContext<PropertiesContextType | null>(null);

type Props = {
    children: React.ReactNode;
};

export function PropertiesProvider({ children }: Props) {
    const [state, dispatch] = useReducer(propertiesReducer, initialState);

    useEffect(() => {
        const init = async () => {
            try {
                const documentJson: any = {};
                documentJson.filters = [{ method: 'eq', fieldName: '_code', value: '_properties' }];

                const document: any = {};
                document.metaCode = '_meta';
                document.collectionName = '_meta';
                document.documentJson = documentJson;

                const response = await listDocument(document);

                const { data }: any = response;

                if (data && data?.length > 0) {
                    dispatch({
                        type: PROPERTIES,
                        payload: {
                            commonProperties: data?.[0]?._data?.common ?? initialState.commonProperties,
                            webProperties: data?.[0]?._data?.webs[0] ?? initialState.webProperties,
                            appProperties: data?.[0]?._data?.apps[0] ?? initialState.appProperties,
                            socket: data?.[0]?._data?.socket ?? initialState.socket,
                            isNickName: data?.[0]?._data?.apps?.[0]?.settings?.isNickName ?? initialState.isNickName,
                            defaultUserImage:
                                data?.[0]?._data?.apps?.[0]?.settings?.defaultUserImage ??
                                initialState.defaultUserImage,
                        },
                    });
                } else {
                    dispatch({
                        type: ERROR,
                    });
                }
            } catch (err) {
                console.error(`Properties provider Exception :${err}`);
            }
        };

        init();
    }, [dispatch]);

    const value = useMemo<PropertiesContextType>(
        () => ({
            ...state,
            isInitialized: state.isInitialized,
            webProperties: state.webProperties,
            appProperties: state.appProperties,
            commonProperties: state.commonProperties,
            socket: state.socket,
            isNickName: state.isNickName,
            defaultUserImage: state.defaultUserImage,
        }),
        [state],
    );

    if (state.isInitialized !== undefined && !state.isInitialized) {
        return <SplashScreen />;
    }

    return <PropertiesContext.Provider value={value}>{children}</PropertiesContext.Provider>;
}

export default PropertiesContext;
