import { useContext } from 'react';

import PropertiesContext from 'src/contexts/PropertiesContext';

// ==============================|| AUTH HOOKS ||============================== //

const useProperties = () => {
    const context = useContext(PropertiesContext);

    if (!context) throw new Error('context must be use inside provider');

    return context;
};

export default useProperties;
