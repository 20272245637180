// third-party
import _ from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { MODE } from 'src/utils/global-options';

const initialState = {
    // status
    isMore: true,
    isInitialized: false,

    rows: [] as any[],
    recordsFiltered: 0,
    recordsTotal: 0,

    // filter
    skip: 0,
    limit: 10,

    selectedRow: null,
};

const notice = createSlice({
    name: 'notice',
    initialState,
    reducers: {
        setInitialState: () => initialState,
        setInitialStateForSearch: (state) => {
            state.isMore = initialState.isMore;
            state.isInitialized = initialState.isInitialized;

            state.rows = initialState.rows;
            state.recordsFiltered = initialState.recordsFiltered;
            state.recordsTotal = initialState.recordsTotal;

            // filter
            state.skip = initialState.skip;
            state.limit = initialState.limit;
        },
        setInitialStateForRow: (state) => {
            state.selectedRow = initialState.selectedRow;
        },
        setRequestAggregateDatas(state, action) {
            const { data } = action.payload;
            const { recordsFiltered, recordsTotal } = data;

            if (data.data.length > 0) {
                state.isMore = true;
                state.skip += state.limit;

                const map = new Map();
                state.rows.concat(data.data).forEach((item) => {
                    map.set(item?._id?.$oid, item);
                });

                state.rows = Array.from(map.values());
                state.recordsFiltered = recordsFiltered;
                state.recordsTotal = recordsTotal;
            } else {
                state.isMore = false;
            }
        },
        setUnshiftRows(state, action) {
            const $rows = _.cloneDeep(state.rows);
            $rows.unshift(action.payload);

            state.rows = $rows;
        },
        deleteSuccess(state, action) {
            const _id = action.payload;

            const $rows = _.cloneDeep(state.rows);
            const $filteredRows = $rows.filter((row) => row?._id?.$oid !== _id?.$oid);

            state.rows = $filteredRows;
        },
        setSelectedRow(state, action) {
            state.selectedRow = action.payload;
        },
    },
});

// Reducer
export default notice.reducer;

export const {
    setInitialState,
    setInitialStateForSearch,
    setInitialStateForRow,
    setRequestAggregateDatas,
    setUnshiftRows,
    deleteSuccess,
    setSelectedRow,
} = notice.actions;
