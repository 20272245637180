// third-party
import _ from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { format } from 'date-fns';

// service
import { listDocument } from 'src/service/global-service';

// utils
import { dispatch } from 'src/store';

const initialState = {
    paymentPageData: null,

    payerName: '',
    payerNumber: '',

    payerNameError: false,
};

const payment = createSlice({
    name: 'payment',
    initialState,
    reducers: {
        setInitialState: () => initialState,
        setPaymentPageData(state, action) {
            state.paymentPageData = action.payload;
        },
        setPayerName(state, action) {
            state.payerName = action.payload;
        },
        setPayerNameError(state, action) {
            state.payerNameError = action.payload;
        },
        setPayerNumber(state, action) {
            state.payerNumber = action.payload;
        },
    },
});

// Reducer
export default payment.reducer;

export const { setInitialState, setPaymentPageData, setPayerName, setPayerNumber, setPayerNameError } = payment.actions;

export function getPaymentPageData(paymentPageCode: any) {
    return async () => {
        const document: any = {};

        document.metaCode = 'paymentPage';
        document.collectionName = 'paymentPage';

        const documentJson: any = {};
        documentJson.filters = [{ method: 'eq', fieldName: 'code', value: paymentPageCode }];
        documentJson.skip = 0;
        documentJson.limit = 0;
        documentJson.sorts = [{ fieldName: '_createTime', method: 'descending' }];
        document.documentJson = documentJson;

        const response = await listDocument(document);
        const { data }: any = response;

        return response;
    };
}
