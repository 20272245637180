import { useRef, useEffect } from 'react';

import Stack from '@mui/material/Stack';
import Popover from '@mui/material/Popover';
import { appBarClasses } from '@mui/material/AppBar';

import { usePathname } from 'src/routes/hooks';
import { useBoolean } from 'src/hooks/use-boolean';
import { useActiveLink } from 'src/routes/hooks/use-active-link';

import { NavListProps, NavConfigProps } from '../types';

import NavItem from './nav-item';

// ----------------------------------------------------------------------

type NavListRootProps = {
    data: NavListProps;
    depth: number;
    hasChild: boolean;
    config: NavConfigProps;
};

export default function NavList({ data, depth, hasChild, config }: NavListRootProps) {
    const navRef = useRef(null);

    const pathname = usePathname();

    const active = useActiveLink(data.path, hasChild);

    const externalLink = data.path.includes('http');

    const listOpen = useBoolean();

    useEffect(() => {
        if (listOpen.value) {
            listOpen.onFalse();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);

    useEffect(() => {
        const appBarEl = Array.from(document.querySelectorAll(`.${appBarClasses.root}`)) as Array<HTMLElement>;

        // Reset styles when hover
        const styles = () => {
            document.body.style.overflow = '';
            document.body.style.padding = '';
            // Apply for Window
            appBarEl.forEach((elem) => {
                elem.style.padding = '';
            });
        };

        if (listOpen.value) {
            styles();
        } else {
            styles();
        }
    }, [listOpen.value]);

    return (
        <>
            <NavItem
                ref={navRef}
                item={data}
                depth={depth}
                open={listOpen.value}
                active={active}
                externalLink={externalLink}
                onMouseEnter={listOpen.onTrue}
                onMouseLeave={listOpen.onFalse}
                config={config}
            />

            {hasChild && (
                <Popover
                    open={listOpen.value}
                    anchorEl={navRef.current}
                    anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
                    transformOrigin={{ vertical: 'center', horizontal: 'left' }}
                    slotProps={{
                        paper: {
                            onMouseEnter: listOpen.onTrue,
                            onMouseLeave: listOpen.onFalse,
                            sx: {
                                mt: 0.5,
                                width: 160,
                                ...(listOpen.value && {
                                    pointerEvents: 'auto',
                                }),
                            },
                        },
                    }}
                    sx={{
                        pointerEvents: 'none',
                    }}
                >
                    <NavSubList data={data.children} depth={depth} config={config} />
                </Popover>
            )}
        </>
    );
}

// ----------------------------------------------------------------------

type NavListSubProps = {
    data: NavListProps[];
    depth: number;
    config: NavConfigProps;
};

function NavSubList({ data, depth, config }: NavListSubProps) {
    return (
        <Stack spacing={0.5}>
            {data.map((list) => (
                <NavList
                    key={list.title + list.path}
                    data={list}
                    depth={depth + 1}
                    hasChild={!!list.children}
                    config={config}
                />
            ))}
        </Stack>
    );
}
