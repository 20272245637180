export const languageOptions = [
    { id: 'en', name: 'English' },
    { id: 'ko', name: 'Korean' },
];

// module constant
export const MODE = {
    EDIT: 'edit',
    READ: 'read',
    CREATE: 'create',
    LIST: 'list',
};
