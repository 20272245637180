// action - state management
import { PROPERTIES, ERROR } from 'src/store/actions';

// ==============================|| ACCOUNT REDUCER ||============================== //

const initialState = {
    isInitialized: false,
    webProperties: null,
    appProperties: null,
    commonProperties: null,
    socket: null,
    isNickName: true,
    defaultUserImage: '',
};

// eslint-disable-next-line default-param-last
const propertiesReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case ERROR: {
            return {
                ...initialState,
            };
        }
        case PROPERTIES: {
            const { commonProperties, webProperties, appProperties, socket, isNickName, defaultUserImage } =
                action.payload;

            return {
                ...state,
                isInitialized: true,
                commonProperties,
                webProperties,
                appProperties,
                socket,
                isNickName,
                defaultUserImage,
            };
        }
        default: {
            return { ...state };
        }
    }
};

export default propertiesReducer;
