import { lazy } from 'react';

// utils
import AuthGuard from 'src/utils/guard/auth-gaurd';

// ----------------------------------------------------------------------

const AccountPage = lazy(() => import('src/pages/account'));

// ----------------------------------------------------------------------

export const accountRoutes = [
    {
        path: 'account/:uid',
        children: [
            {
                element: (
                    <AuthGuard>
                        <AccountPage />
                    </AuthGuard>
                ),
                index: true,
            },
        ],
    },
];
