import 'src/global.css';

// ----------------------------------------------------------------------

import ThemeProvider from 'src/theme';
import LanguageProvider from 'src/providers/LanguageProvider';
import Router from 'src/routes/sections';
import { LocalizationProvider } from 'src/locales';
import ProgressBar from 'src/components/progress-bar';
import MotionLazy from 'src/components/animate/motion-lazy';
import { useScrollToTop } from 'src/hooks/use-scroll-to-top';
import { SettingsDrawer, SettingsProvider } from 'src/components/settings';
import { PropertiesProvider } from 'src/contexts/PropertiesContext';
import { FirebaseProvider as AuthProvider } from 'src/contexts/FirebaseContext';
import { CustomDialog } from 'src/components/dialog';

// ----------------------------------------------------------------------

export default function App() {
    useScrollToTop();

    return (
        <LanguageProvider>
            <PropertiesProvider>
                <AuthProvider>
                    <LocalizationProvider>
                        <SettingsProvider
                            defaultSettings={{
                                themeMode: 'light', // 'light' | 'dark'
                                themeDirection: 'ltr', //  'rtl' | 'ltr'
                                themeColorPresets: 'default', // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
                            }}
                        >
                            <ThemeProvider>
                                <MotionLazy>
                                    <ProgressBar />
                                    <SettingsDrawer />
                                    <Router />
                                    <CustomDialog />
                                </MotionLazy>
                            </ThemeProvider>
                        </SettingsProvider>
                    </LocalizationProvider>
                </AuthProvider>
            </PropertiesProvider>
        </LanguageProvider>
    );
}
