import { lazy } from 'react';

import { Outlet } from 'react-router-dom';

// ----------------------------------------------------------------------

// base
const CommunityBase = lazy(() => import('src/pages/community/community-base'));

// page
const CommunityPage = lazy(() => import('src/pages/community/community'));
const CommunityDetail = lazy(() => import('src/pages/community/community-detail'));

// ----------------------------------------------------------------------

export const communityRoutes = [
    {
        path: 'community',
        element: (
            <CommunityBase>
                <Outlet />
            </CommunityBase>
        ),
        children: [
            { element: <CommunityPage />, index: true },
            { path: 'detail/:oid', element: <CommunityDetail /> },
        ],
    },
];
