import { lazy } from 'react';

// ----------------------------------------------------------------------

const FaqPage = lazy(() => import('src/pages/faq'));

// ----------------------------------------------------------------------

export const faqRoutes = [
    {
        path: 'faq',
        children: [{ element: <FaqPage />, index: true }],
    },
];
