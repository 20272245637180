import { lazy } from 'react';

import { Outlet } from 'react-router-dom';

// ----------------------------------------------------------------------

// base
const PaymentBase = lazy(() => import('src/pages/payment/payment-base'));

// page
const PaymentPage = lazy(() => import('src/pages/payment/payment'));
const PaymentCompletePage = lazy(() => import('src/pages/payment/pages/payment-complete-page'));
const PaymentBillingCompletePage = lazy(() => import('src/pages/payment/pages/payment-billing-complete-page'));
const PaymentSuccessPage = lazy(() => import('src/pages/payment/pages/payment-success-page'));
const PaymentFailPage = lazy(() => import('src/pages/payment/pages/payment-fail-page'));

// ----------------------------------------------------------------------

export const paymentPageRoutes = [
    {
        path: 'payment-page/:paymentPageCode',
        element: (
            <PaymentBase>
                <Outlet />
            </PaymentBase>
        ),
        children: [{ element: <PaymentPage />, index: true }],
    },
    /* ===== 결제 처리 페이지 ==== */
    // 단건 결제 처리
    { path: 'payment-page/complete', element: <PaymentCompletePage /> },
    // 정기 결제 처리
    { path: 'payment-page/billingComplete', element: <PaymentBillingCompletePage /> },

    /* ===== 결제 결과 페이지 ===== */
    { path: 'payment-page/success', element: <PaymentSuccessPage /> },
    { path: 'payment-page/fail', element: <PaymentFailPage /> },
];
