// ----------------------------------------------------------------------

export const paths = {
    // ohmyapp - 오마이앱 경로
    // 뒤에 :param과 같이 붙혀서 파라미터 변수 사용 가능
    community: {
        root: '/community',
        create: '/community/create',
        detail: '/community/detail',
    },
    notice: {
        root: '/notice',
        detail: '/notice/detail',
    },
    account: {
        // :uid
        root: '/account',
    },
    appMaker: {
        root: '/app-maker',
        complete: '/app-maker/complete',
    },
    plans: {
        root: '/plans',
    },
    faq: {
        root: '/faq',
    },
    appInfo: {
        // :uid 포함
        root: '/app-info',
    },
    service: {
        root: '/service',
    },

    // 샘플 경로 굳이 지우지는 말것
    // Marketing
    marketing: {
        root: '/marketing',
        services: '/marketing/services',
        caseStudies: '/marketing/case-studies',
        caseStudy: `/marketing/case-study`,
        posts: '/marketing/posts',
        post: `/marketing/post`,
        about: '/marketing/about',
        contact: '/marketing/contact',
    },
    // Travel
    travel: {
        root: '/travel',
        tours: '/travel/tours',
        tour: `/travel/tour`,
        checkout: '/travel/checkout',
        orderCompleted: '/travel/order-completed',
        posts: '/travel/posts',
        post: `/travel/post`,
        about: '/travel/about',
        contact: '/travel/contact',
    },
    // Career
    career: {
        root: '/career',
        jobs: '/career/jobs',
        job: `/career/job`,
        posts: '/career/posts',
        post: `/career/post`,
        about: '/career/about',
        contact: '/career/contact',
    },
    // E-learning
    eLearning: {
        root: '/e-learning',
        courses: '/e-learning/courses',
        course: `/e-learning/course`,
        posts: '/e-learning/posts',
        post: `/e-learning/post`,
        about: '/e-learning/about',
        contact: '/e-learning/contact',
    },
    // E-commerce
    eCommerce: {
        root: '/e-commerce',
        products: '/e-commerce/products',
        product: `/e-commerce/product`,
        cart: '/e-commerce/cart',
        checkout: `/e-commerce/checkout`,
        orderCompleted: '/e-commerce/order-completed',
        wishlist: `/e-commerce/wishlist`,
        compare: `/e-commerce/compare`,
        account: {
            root: `/e-commerce/account`,
            personal: `/e-commerce/account/personal`,
            wishlist: `/e-commerce/account/wishlist`,
            vouchers: `/e-commerce/account/vouchers`,
            orders: `/e-commerce/account/orders`,
            payment: `/e-commerce/account/payment`,
        },
    },
    // Auth
    loginCover: '/auth/login-cover',
    loginIllustration: '/auth/login-illustration',
    registerCover: '/auth/register-cover',
    registerIllustration: '/auth/register-illustration',
    forgotPassword: '/auth/forgot-password',
    verify: '/auth/verify',
    resetPassword: '/auth/reset-password',
    // Auth ohmyapp
    loginBackground: '/auth/login-background',
    registerBackground: '/auth/register-background',
    terms: '/auth-public/terms',
    privacyPolicy: '/auth-public/privacy-policy',
    outsourcing: '/auth-public/outsourcing',
    // Common
    maintenance: '/maintenance',
    comingsoon: '/coming-soon',
    pricing01: '/pricing-01',
    pricing02: '/pricing-02',
    payment: '/payment',
    support: '/support',
    page404: '/404',
    page500: '/500',
    // Components
    components: {
        root: '/components',
        animate: '/components/animate',
        carousel: '/components/carousel',
        countUp: '/components/count-up',
        form: '/components/form-validation',
        icons: '/components/icons',
        image: '/components/image',
        label: '/components/label',
        lightbox: '/components/lightbox',
        markdown: '/components/markdown',
        megaMenu: '/components/mega-menu',
        navigation: '/components/navigation-bar',
        scroll: '/components/scroll',
        player: '/components/player',
        textMaxLine: '/components/text-max-line',
    },
    // Others
    pages: '/pages',
    docs: 'https://zone-docs.vercel.app',
    license: 'https://material-ui.com/store/license/#i-standard-license',
    minimalStore: 'https://material-ui.com/store/items/minimal-dashboard',
    zoneStore: 'https://mui.com/store/items/zone-landing-page/',
    figmaPreview: 'https://www.figma.com/file/X6OyiGHF8dnTk3aT38P0oN/%5BPreview%5D-Zone_Web.30.03.23?node-id=0-1',
};
