// material ui
import Link from '@mui/material/Link';
import Divider from '@mui/material/Divider';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { alpha, styled, useTheme } from '@mui/material/styles';
import Stack, { StackProps } from '@mui/material/Stack';
import Button, { buttonClasses } from '@mui/material/Button';

// project import
import { Image } from 'src/components/image';
import Logo from 'src/components/logo';
import Iconify from 'src/components/iconify';
import { useResponsive } from 'src/hooks/use-responsive';

// thrid-party
import { useTranslation } from 'react-i18next';

// routes
import { useNavigate } from 'react-router';
import { paths } from 'src/routes/paths';

// ----------------------------------------------------------------------

export const _socials = [
    {
        value: 'youtube',
        label: 'Youtube',
        icon: 'carbon:logo-youtube',
        color: '#1877F2',
    },
];

// ----------------------------------------------------------------------

export default function Footer() {
    const theme = useTheme();

    const { t } = useTranslation();
    const navigate = useNavigate();

    const mainFooter = (
        <>
            <Divider />

            <Container
                sx={{
                    overflow: 'hidden',
                    mt: '40px',
                    mb: '40px',
                }}
            >
                <Grid container spacing={3} justifyContent={{ md: 'space-between' }}>
                    <Grid xs={12} md={8} sx={{ mb: { xs: '10px', md: '0px' } }}>
                        <Stack spacing={{ xs: 3, md: 4 }}>
                            <Stack alignItems="flex-start" spacing={1}>
                                <Logo
                                    sx={{ width: '110px', height: '100%', objectFit: 'cover', ml: '2px', mt: '10px' }}
                                />

                                <Typography
                                    variant="body2"
                                    sx={{ color: '#222222', fontSize: '13px', fontWeight: 400 }}
                                >
                                    {t('ohmyappHomepage.main.section1.main_title1')}{' '}
                                    {t('ohmyappHomepage.main.section1.main_title2')}
                                </Typography>
                            </Stack>
                        </Stack>
                    </Grid>
                    <Grid xs={12} md={4}>
                        <Stack spacing={1.5} direction="row" justifyContent={{ xs: 'center', md: 'flex-end' }}>
                            {_socials.map((social) => (
                                <Link
                                    key={social.value}
                                    href="https://www.youtube.com/channel/UCYfFaOlTOvVKzQfRLmj12jw"
                                    target="_blank"
                                    rel="noopener"
                                >
                                    <IconButton
                                        color="primary"
                                        sx={{
                                            border: `1px solid ${alpha(theme.palette.primary.main, 0.5)}`,
                                        }}
                                    >
                                        <Iconify icon={social.icon} />
                                    </IconButton>
                                </Link>
                            ))}
                            <AppStoreButton />
                        </Stack>
                    </Grid>
                </Grid>
            </Container>

            <Container>
                <Stack direction={{ xs: 'column', md: 'column' }} sx={{ mb: '40px', textAlign: 'left' }}>
                    <Typography
                        variant="caption"
                        sx={{ color: '#222222', fontSize: '12px', fontWeight: 700, mb: '8px' }}
                    >
                        {t('ohmyappHomepage.main.footer.inc')}
                    </Typography>
                    <Typography sx={{ color: '#7a7a7a', fontSize: '13px', mb: '40px' }}>
                        {t('ohmyappHomepage.main.footer.caption')}
                    </Typography>
                    <Stack
                        direction={{ xs: 'column', md: 'row' }}
                        justifyContent={{ xs: 'center', md: 'space-between' }}
                        spacing={{ xs: 2, md: 1.5 }}
                    >
                        <Typography sx={{ color: '#7a7a7a', fontSize: '13px' }}>
                            {t(
                                'ohmyappHomepage.main.footer.copyright',
                                'Copyright 2023 K-Rangers, Inc. All rights reserved.',
                            )}
                        </Typography>
                        <Stack direction="row" spacing={2} justifyContent={{ xs: 'center' }}>
                            <Typography
                                sx={{
                                    color: '#7a7a7a',
                                    fontSize: '13px',
                                    cursor: 'pointer',
                                }}
                                onClick={() => navigate(paths.notice.root)}
                            >
                                {t('ohmyappHomepage.main.footer.notice', 'Notice')}
                            </Typography>
                            <Typography sx={{ color: '#d2d2d2', fontSize: '13px' }}>|</Typography>
                            <Typography
                                sx={{
                                    color: '#7a7a7a',
                                    fontSize: '13px',
                                    cursor: 'pointer',
                                }}
                                onClick={() => window.open(paths.terms, '_blank')}
                            >
                                {t('ohmyappHomepage.main.footer.terms', '서비스 이용약관')}
                            </Typography>
                            <Typography sx={{ color: '#d2d2d2', fontSize: '13px' }}>|</Typography>
                            <Typography
                                sx={{
                                    color: '#7a7a7a',
                                    fontSize: '13px',
                                    cursor: 'pointer',
                                    fontWeight: '600',
                                }}
                                onClick={() =>
                                    window.open(`https://app.catchsecu.com/document/P/62265c184dca909`, '_blank')
                                }
                            >
                                {t('ohmyappHomepage.main.footer.privacy', '개인정보 처리방침')}
                            </Typography>
                        </Stack>
                    </Stack>
                </Stack>
            </Container>
        </>
    );

    return <footer>{mainFooter}</footer>;
}

// -----------------------------------------------------------------------------------------------

function AppStoreButton({ ...other }: StackProps) {
    const mdUp = useResponsive('up', 'md');

    return (
        <Stack direction="row" flexWrap="wrap" justifyContent={mdUp ? 'flex-start' : 'center'} spacing={1.5} {...other}>
            <Stack alignItems="flex-start">
                <Button
                    target="_blank"
                    rel="noopener"
                    sx={{
                        borderRadius: '20px',
                        fontSize: '13px',
                        width: '128px',
                        height: '40px',
                        overflow: 'visible',
                        fontWeight: 400,
                        border: '1px solid #ebebeb',
                    }}
                    href="https://apps.apple.com/app/1639691652"
                >
                    <Image
                        visibleByDefault
                        disabledEffect
                        src="/assets/images/ohmy_home/iconAppStore@3x.png"
                        sx={{
                            maxWidth: '17%',
                            maxHeight: '100%',
                            mr: '8px',
                            mb: '5px',
                        }}
                    />
                    App store
                </Button>
            </Stack>

            <Stack alignItems="flex-start">
                <Button
                    target="_blank"
                    rel="noopener"
                    sx={{
                        borderRadius: '20px',
                        fontSize: '13px',
                        width: '128px',
                        height: '40px',
                        overflow: 'visible',
                        fontWeight: 400,
                        border: '1px solid #ebebeb',
                    }}
                    href="https://play.google.com/store/apps/details?id=kr.co.ohmyapp&pli=1"
                >
                    <Image
                        visibleByDefault
                        disabledEffect
                        src="/assets/images/ohmy_home/iconGooglePlay@3x.png"
                        sx={{
                            width: '20px',
                            height: '20px',
                            paddingRight: '6px',
                            mb: '8px',
                        }}
                    />
                    Google Play
                </Button>
            </Stack>
        </Stack>
    );
}
