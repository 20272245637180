// react
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

// material ui
import { Stack, Box, Avatar, Button, Typography, Menu, MenuItem, Divider, IconButton } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

// routes
import { paths } from 'src/routes/paths';

// context
import useAuth from 'src/hooks/use-auth';

// hook
import { useResponsive } from 'src/hooks/use-responsive';

// project import
import { Image } from 'src/components/image';
import SvgColor from 'src/components/svg-color';

// third-party
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

// service
import { listDocument, ohmyappLoginIntegration } from 'src/service/global-service';

// utils
import { getImageSource, getAvatarSource } from 'src/utils/functions';
import { isEmpty, isUndefined } from 'src/utils/typeof';

import Label from 'src/components/label';
import Iconify from 'src/components/iconify/iconify';

// ----------------------------------------------------------------------

export default function NavProfile() {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const mdUp = useResponsive('up', 'md');

    const { isLoggedIn, logout, userInfo } = useAuth();
    const { enqueueSnackbar } = useSnackbar();

    const [avatarUrl, setAvatarUrl] = useState('/assets/images/avatar/ghost_default.png');

    const [serviceItems, setServiceItems] = useState([]);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    // 페이지 이동
    const handleNavigate = (path: any) => {
        navigate(path, { replace: true });
    };

    // 메뉴 열기
    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    // 메뉴 닫기
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMenuToggle = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    // 내 계정 서비스 조회
    const getServices = useCallback(async () => {
        const $userId = userInfo?._id;
        if ($userId) {
            const documentJson: any = {};
            documentJson.filters = [
                { method: 'eq', fieldName: 'user._id', value: $userId },
                { method: 'ne', fieldName: 'status', value: 'remove' },
            ];
            documentJson.sorts = [{ fieldName: '_createTime', method: 'descending' }];
            documentJson.skip = 0;
            documentJson.limit = 0;

            const document: any = {};
            document.eventType = 'service';
            document.collectionName = 'service';
            document.documentJson = documentJson;

            const response = await listDocument(document);
            const { data }: any = response;

            setServiceItems(data);
        }
    }, [userInfo]);

    const handleMoveToAdminWeb = async (serviceId = '', domain = '', status = '') => {
        if (status !== 'running') {
            // 서비스가 상태가 올바르지 않습니다. 관리자에게 문의하세요.
            enqueueSnackbar(t('ohmyappHomepage.main.header.nav_profile.admin_web_err_msg'), { variant: 'warning' });
            return false;
        }

        window.open(`https://${domain}`, '_blank');

        // if (serviceId !== '' && domain !== '') {
        //     const document: any = {};
        //     document.serviceId = serviceId;
        //     document.domain = domain;
        //     document.isShortToken = true;

        //     const response = await ohmyappLoginIntegration(document);

        //     const { accessToken, code } = response;

        //     if ((!isUndefined(accessToken) || !isEmpty(accessToken)) && code === '1') {
        //         // window.open(`http://localhost:3000/?uniqueKey=${accessToken}`, '_blank');
        //         // window.open(`https://${domain}/?uniqueKey=${accessToken}`, '_blank');

        //     }
        // }
    };

    useEffect(() => {
        if (isLoggedIn) {
            // 아바타만 로컬에 정의 후 서비스 호출
            const $avatarUrl = userInfo?.avatar;
            if ($avatarUrl) {
                setAvatarUrl($avatarUrl);
            }

            if (open || isMenuOpen) {
                getServices();
            }
        }
    }, [userInfo, isLoggedIn, getServices, open, isMenuOpen]);

    return (
        <Stack
            direction={mdUp ? 'row' : 'column'}
            alignItems={mdUp ? 'center' : 'left'}
            sx={{
                mx: mdUp ? '40px' : '0',
                cursor: 'pointer',
                color: 'common.white',
            }}
        >
            <Box>
                <Grid container>
                    <Grid xs={10.5}>
                        <Avatar
                            src={getAvatarSource(avatarUrl)}
                            sx={{ width: 40, height: 40, ml: mdUp ? 0 : 2 }}
                            onClick={mdUp ? handleClick : handleMenuToggle}
                            aria-controls={open ? 'profile-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                        />
                    </Grid>
                    {!mdUp && (
                        <Grid xs={1.5}>
                            {isMenuOpen ? (
                                <IconButton onClick={handleMenuToggle} sx={{ justifyContent: 'right' }}>
                                    <Iconify icon="carbon:chevron-up" />
                                </IconButton>
                            ) : (
                                <IconButton onClick={handleMenuToggle} sx={{ justifyContent: 'right' }}>
                                    <Iconify icon="carbon:chevron-down" />
                                </IconButton>
                            )}
                        </Grid>
                    )}
                </Grid>
            </Box>
            {mdUp && (
                <Menu
                    id="profile-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    PaperProps={{
                        elevation: 0,
                        sx: {
                            minWidth: 280,
                            padding: '0px',
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 1.5,
                            '& .MuiAvatar-root': {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1,
                            },
                            '&:before': {
                                content: '""',
                                display: 'block',
                                marginLeft: !mdUp ? '18px' : '0',
                                position: !mdUp ? 'static' : 'absolute',
                                top: 0,
                                right: 14,
                                width: 10,
                                height: 10,
                                bgcolor: 'background.paper',
                                transform: 'translateY(-50%) rotate(45deg)',
                                zIndex: 0,
                            },
                        },
                    }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                    {userInfo?.status === 'admin' && (
                        <Box sx={{ p: '16px 0px 0px 0px' }}>
                            <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                                sx={{ py: '8px', mb: '8px' }}
                            >
                                <Box display="flex" alignItems="center">
                                    <Typography variant="subtitle1" sx={{ pl: '16px' }}>
                                        {/* 전체 서비스 */}
                                        {t('ohmyappHomepage.main.header.nav_profile.services')}
                                    </Typography>
                                </Box>
                                <Label sx={{ ml: '115px', mb: '5px', fontWeight: 500 }}>
                                    {/* 멤버 */}
                                    {t('ohmyappHomepage.main.header.nav_profile.member')}
                                </Label>
                                <Box onClick={() => handleNavigate('/service')}>
                                    <SvgColor
                                        src="/assets/icons/ic-chevron-right-small.svg"
                                        sx={{ width: 24, height: 24, color: '#9a9fa5', cursor: 'pointer', mr: '10px' }}
                                    />
                                </Box>
                            </Box>
                            <Box>
                                <Divider />
                            </Box>
                        </Box>
                    )}
                    <Box sx={{ maxHeight: 600, overflow: 'auto' }}>
                        {serviceItems.map((item: any) => (
                            <Box key={item?._id?.$oid}>
                                <Box sx={{ p: '16px 16px 20px 16px' }}>
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="space-between"
                                        sx={{ py: '8px', mb: '8px' }}
                                    >
                                        <Box display="flex" alignItems="center">
                                            <Image
                                                disabledEffect
                                                src={getImageSource(item?.appIcon)}
                                                sx={{
                                                    width: '40px',
                                                    height: '40px',
                                                    mr: '16px',
                                                    borderRadius: '8px',
                                                }}
                                            />
                                            <Typography variant="subtitle1">{item?.title}</Typography>
                                        </Box>
                                        {/* 서비스 _id path에 담아서 보냄 */}
                                        <Box onClick={() => handleNavigate(`${paths.appInfo.root}/${item._id.$oid}`)}>
                                            <SvgColor
                                                src="/assets/icons/ic-chevron-right-small.svg"
                                                sx={{ width: 24, height: 24, color: '#9a9fa5', cursor: 'pointer' }}
                                            />
                                        </Box>
                                    </Box>
                                    {/* {item?.status === 'running' && ( */}
                                    <Box>
                                        <Button
                                            variant="contained"
                                            onClick={() =>
                                                handleMoveToAdminWeb(item?._id?.$oid, item?.domain, item?.status)
                                            }
                                            sx={{
                                                width: '100%',
                                                height: 40,
                                                backgroundColor: '#f3f4f5',
                                                color: '#6f767e',
                                                '&:hover': {
                                                    backgroundColor: '#f3f4f5',
                                                    color: '#6f767e',
                                                },
                                            }}
                                        >
                                            {/* 관리자 웹 바로가기 */}
                                            {t('ohmyappHomepage.main.header.nav_profile.go_to_admin_web')}
                                        </Button>
                                    </Box>
                                    {/* )} */}
                                </Box>
                                <Box>
                                    <Divider />
                                </Box>
                            </Box>
                        ))}
                    </Box>
                    {/* 유저 _id path에 담아서 보냄 */}
                    <MenuItem
                        sx={{ p: '8px 0px 8px 8px', m: '20px 8px 8px 8px' }}
                        onClick={() => handleNavigate(`${paths.account.root}/${userInfo._id.$oid}`)}
                    >
                        <Image
                            src="/assets/icons/gray-ghost-icon.svg"
                            sx={{ width: '7%', height: '7%', cursor: 'pointer', mr: '12px' }}
                        />
                        <Typography sx={{ color: '#1d2124', fontSize: '14px', mt: '4px' }}>
                            {/* 내 계정 */}
                            {t('ohmyappHomepage.main.header.nav_profile.my_account')}
                        </Typography>
                    </MenuItem>
                    {/* <MenuItem
                        sx={{ p: '8px 0px 8px 8px', m: '0px 8px 0px 8px' }}
                        onClick={() => handleNavigate(paths.appMaker.root)}
                    > */}
                    {/* <Image
                            src="/assets/icons/create-app-icon.svg"
                            sx={{ width: '7%', height: '7%', cursor: 'pointer', mr: '12px' }}
                        /> */}
                    {/* <Typography sx={{ color: '#1d2124', fontSize: '14px', mt: '3px' }}> */}
                    {/* 앱 만들기 */}
                    {/* {t('ohmyappHomepage.main.header.nav_profile.create_app')} */}
                    {/* </Typography> */}
                    {/* </MenuItem> */}
                    <MenuItem onClick={logout} sx={{ p: '8px 0px 8px 16px', m: '20px 8px 16px 8px' }}>
                        <Typography variant="body2" sx={{ color: '#9a9fa5' }}>
                            {/* 로그아웃 */}
                            {t('ohmyappHomepage.main.header.nav_profile.logout')}
                        </Typography>
                    </MenuItem>
                </Menu>
            )}
            {!mdUp && isMenuOpen && (
                <Stack>
                    {userInfo?.status === 'admin' && (
                        <Box sx={{ p: '16px 0px 5px 0px' }}>
                            <Box display="flex" alignItems="center" justifyContent="space-between">
                                <Box display="flex" alignItems="center">
                                    <Typography
                                        sx={{ color: '#1d2124', fontSize: '15px,', fontWeight: 700, ml: '15px' }}
                                    >
                                        {/* 전체 서비스 */}
                                        {t('ohmyappHomepage.main.header.nav_profile.services')}
                                    </Typography>
                                </Box>
                                <Label sx={{ ml: '185px', my: '16px', fontWeight: 500 }}>
                                    {/* 멤버 */}
                                    {t('ohmyappHomepage.main.header.nav_profile.member')}
                                </Label>
                                <Box onClick={() => handleNavigate('/service')}>
                                    <SvgColor
                                        src="/assets/icons/ic-chevron-right-small.svg"
                                        sx={{
                                            width: 24,
                                            height: 24,
                                            color: '#9a9fa5',
                                            cursor: 'pointer',
                                            mr: '10px',
                                            mt: '2px',
                                        }}
                                    />
                                </Box>
                            </Box>
                            <Box>
                                <Divider />
                            </Box>
                        </Box>
                    )}
                    {serviceItems.map((item: any) => (
                        <Box key={item?._id?.$oid}>
                            <Box sx={{ p: '16px 16px 20px 16px' }}>
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    sx={{ py: '8px', mb: '8px' }}
                                >
                                    <Box display="flex" alignItems="center">
                                        <Image
                                            disabledEffect
                                            src={getImageSource(item?.appIcon)}
                                            sx={{
                                                width: '40px',
                                                height: '40px',
                                                mr: '16px',
                                                borderRadius: '8px',
                                            }}
                                        />
                                        <Typography sx={{ color: '#1d2124', fontSize: '15px', fontWeight: 700 }}>
                                            {item?.title}
                                        </Typography>
                                    </Box>
                                    {/* 서비스 _id path에 담아서 보냄 */}
                                    <Box onClick={() => handleNavigate(`${paths.appInfo.root}/${item._id.$oid}`)}>
                                        <SvgColor
                                            src="/assets/icons/ic-chevron-right-small.svg"
                                            sx={{ width: 24, height: 24, color: '#9a9fa5', cursor: 'pointer' }}
                                        />
                                    </Box>
                                </Box>
                                {/* <Box>
                                    <Button
                                        variant="contained"
                                        target="_blank"
                                        onClick={() =>
                                            handleMoveToAdminWeb(item?._id?.$oid, item?.domain, item?.status)
                                        }
                                        href={`https://${item?.domain}`}
                                        rel="noopener"
                                        sx={{
                                            width: '100%',
                                            height: 40,
                                            backgroundColor: '#f3f4f5',
                                            color: '#6f767e',
                                            '&:hover': {
                                                backgroundColor: '#f3f4f5',
                                                color: '#6f767e',
                                            },
                                            fontSize: '13px',
                                        }}
                                    >
                                        // 관리자 웹 바로가기
                                        {t('ohmyappHomepage.main.header.nav_profile.go_to_admin_web')}
                                    </Button>
                                </Box> */}
                            </Box>
                            <Box>
                                <Divider />
                            </Box>
                        </Box>
                    ))}
                    {/* 유저 _id path에 담아서 보냄 */}
                    <Box
                        sx={{ p: '8px 0px 8px 8px', m: '20px 8px 8px 8px', display: 'flex' }}
                        onClick={() => handleNavigate(`${paths.account.root}/${userInfo._id.$oid}`)}
                    >
                        <Image
                            src="/assets/icons/gray-ghost-icon.svg"
                            sx={{ width: '6%', height: '6%', cursor: 'pointer', mr: '12px' }}
                        />
                        <Typography sx={{ color: '#1d2124', fontSize: '14px', mt: '4px' }}>
                            {/* 내 계정 */}
                            {t('ohmyappHomepage.main.header.nav_profile.my_account')}
                        </Typography>
                    </Box>
                    <Box
                        sx={{ p: '8px 0px 8px 8px', m: '0px 8px 0px 8px', display: 'flex' }}
                        onClick={() => handleNavigate(paths.appMaker.root)}
                    >
                        <Image
                            src="/assets/icons/create-app-icon.svg"
                            sx={{ width: '6%', height: '6%', cursor: 'pointer', mr: '12px' }}
                        />
                        <Typography sx={{ color: '#1d2124', fontSize: '14px', mt: '3px' }}>
                            {/* 앱 만들기 */}
                            {t('ohmyappHomepage.main.header.nav_profile.create_app')}
                        </Typography>
                    </Box>
                    <Box onClick={logout} sx={{ p: '8px 0px 8px 16px', m: '0px 8px 0px 8px' }}>
                        <Typography variant="body2" sx={{ color: '#9a9fa5', fontSize: '14px' }}>
                            {/* 로그아웃 */}
                            {t('ohmyappHomepage.main.header.nav_profile.logout')}
                        </Typography>
                    </Box>
                </Stack>
            )}
        </Stack>
    );
}
